import styled from "styled-components";

export const Container = styled.div`
  padding: 5rem 0;
  background-image: url(https://images.dowell-ht.com/images/react-dowell-ht/bg-003.png);
  background-size: cover;
  background-position: center;
`;
export const Content = styled.div`
  display: grid;
  justify-items: center;
  * {
    line-height: 200%;
  }
  > :not(:last-child) {
    margin-bottom: 30px;
  }
  > img {
    max-height: 600px;
  }
  div {
    &.content {
      > :not(:last-child) {
        margin-bottom: 30px;
      }
    }
  }
  h2 {
    font-weight: var(--bold);
    color: var(--text-60);
    line-height: 100%;
    font-size: 3rem;
  }
  p,
  li {
    font-weight: var(--light);
    font-size: 1.25rem;
    color: var(--text-black);
  }
  ul {
    list-style: none;
    li::before {
      content: "";
      display: inline-block;
      height: 10px;
      width: 10px;
      background-color: var(--bg-10);
      border-radius: 20px;
      margin-right: 10px;
    }
  }
  @media screen and (min-width: 768px) {
    grid-template-columns: 0.8fr 1fr;
    justify-items: start;
  }

  div.products-display {
  }
`;
