import React from "react";
import * as Components from "./styles/Content4.styled";

const Content4 = () => {
  return (
    <>
      <Components.Container>
        <Components.Content className="max-width-1080px grid">
          <div className="spread-30">
            <h3>
              I started to talk about things that people are ashamed of and very
              few talk about:
            </h3>
            <h2 className="content4-h2">Trauma & Clutter</h2>
            <p>
              I wrote a book, with my husband, and developed an online program
              to{" "}
              <strong>
                help people start their journey to cope with their trauma.
              </strong>{" "}
              I also started this so women feeling exactly the same way as me,
              can
              <strong>
                get harmless and resourceful information all at the same place
                delivered at their rhythm
              </strong>{" "}
              - and stop being overwhelmed by the subscriptions to tons of
              emails and newsletters.
            </p>
            <p>
              I continue to share relevant story and tools on my YouTube Channel
              and my Podcast. The information I share is all about{" "}
              <strong>
                guiding you through your struggles, helping you developing the
                right habits
              </strong>
              for you and giving you tools to declutter and organize well.
            </p>
            <p>
              I am excited to launch our new Put That Stuff Down program, to
              <strong>
                provide you with the best tools to deal with trauma
              </strong>{" "}
              and start your decluttering journey. This program is the exact
              starting point I would give my family, my children and my friends
              if they suffer from any trauma (concussion, bad divorce, abusive
              or war situation…).
            </p>
            <p>
              The program can help you train your mind to{" "}
              <strong>develop the path you want to achieve in life,</strong> and
              to know what steps you need to get there and to work on what is
              holding you back from moving forward.
            </p>
            <p>
              I do believe that reducing your stress, calming your mind,
              simplifying your living space and having healthy time practice
              <strong>
                will improve your physical and emotional health.
              </strong>{" "}
              I know that, because I’ve been there. Let me help you achieving
              it.
            </p>
            <p>Your biggest Fan and Friend, </p>
            <h4>Valerie</h4>
          </div>
          <img
            src="https://images.dowell-ht.com/images/react-dowell-ht/image-016.png"
            alt="/"
          />
        </Components.Content>
      </Components.Container>
    </>
  );
};

export default Content4;
