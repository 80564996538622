import styled from "styled-components";

export const Container = styled.div`
  padding: 5rem 0;
`;
export const Content = styled.div`
  .grid {
    display: grid;
    grid-gap: 30px;
    @media screen and (min-width: 768px) {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  .spread-30 {
    > *:not(:last-child) {
      margin-bottom: 30px;
    }
  }
  padding: 2rem;
  border: 2px solid var(--bg-white);

  h2,
  h3 {
    color: var(--text-white);
  }

  h2 {
    font-size: clamp(3rem, 5vw, 4rem);
    &.content4-h2 {
      text-align: left;
    }
  }

  h3 {
    font-weight: var(--light);
    font-size: clamp(1.5rem, 5vw, 2rem);
  }

  h4 {
    font-family: "Playball", sans-serif;
    color: var(--text-white);
    font-size: clamp(4rem, 5vw, 5rem);
  }

  img {
    @media screen and (min-width: 768px) {
      position: sticky;
      top: 0;
    }
  }
`;
