import React from "react";
import * as Components from "./styles/MeetValerie.styled.js";
import Content1 from "../components/meet-valerie/Content1";
import Content2 from "../components/meet-valerie/Content2";
import Content3 from "../components/meet-valerie/Content3";

const MeetValerie = () => {
  return (
    <>
      <Components.Container>
        <Components.Content>
          <Content1 />
          <Content2 />
          <Content3 />
        </Components.Content>
      </Components.Container>
    </>
  );
};

export default MeetValerie;
