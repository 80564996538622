import React from "react";
import * as Components from "./styles/ContactUs.styled";
import Content1 from "../components/contact-us/Content1";

const ContactUs = () => {
  return (
    <>
      <Components.Container>
        <Components.Content>
          <Content1 />
        </Components.Content>
      </Components.Container>
    </>
  );
};

export default ContactUs;
