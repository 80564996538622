import React from "react";
import * as Components from "./styles/PSDBook.styled";

const PSDBook = () => {
  return (
    <>
      <Components.Container>
        <Components.Content className="max-width-1080px">
          <a
            href="https://try.dowellht.com/get-bestseller-clutter-trauma?el=dowellhtmain"
            className="psd-link"
          >
            <img
              src="https://images.dowell-ht.com/images/psd-book-hero-image-001.png"
              className="psd-book-image"
              alt="/"
            />
          </a>
          <div className="psd-book-p">
            <p>The Bestseller on the missing link between trauma and clutter</p>
            <br />
            <p>Over 20 Thousands copies sold</p> <br />
            <p>
              In the bestseller <strong>“Put That Stuff Down”</strong>, Valerie
              and JM states: “You can only achieve what you believe.” They give
              you the information to really understand and start to believe in
              the process.
            </p>{" "}
            <br />
            <p>Get your copy with the powerful bundle with the button below.</p>
            <a
              href="https://try.dowellht.com/get-bestseller-clutter-trauma?el=dowellhtmain"
              className="psd-button"
            >
              Get your book in the bundle for the most value and learnings
            </a>
          </div>
        </Components.Content>
      </Components.Container>
    </>
  );
};

export default PSDBook;
