import React from "react";
import * as Components from "./styles/Home.styled";

import Content1 from "../components/home/Content1";
import Content2 from "../components/home/Content2";
import Content3 from "../components/home/Content3";
import Content4 from "../components/home/Content4";

// import CookieBar from "../components/CookieBar";

const Home = () => {
  // const [showPopUp, setShowPopUp] = useState(true);

  // function hidePopUp() {
  //   setShowPopUp(!showPopUp);
  // }

  return (
    <>
      <Components.Container>
        <Components.Content>
          <Content1 />
          <Content2 />
          <Content3 />
          <Content4 />
        </Components.Content>
        {/* {showPopUp && <CookieBar hidePopUp={hidePopUp} />} */}
      </Components.Container>
    </>
  );
};

export default Home;
