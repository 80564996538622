import "./App.css";
import { useState, useLayoutEffect, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Menu from "./components/Menu";
import Home from "./pages/Home";
import MeetValerie from "./pages/MeetValerie";
import MeetJM from "./pages/MeetJM";
import AboutUs from "./pages/AboutUs";
import ContactUs from "./pages/ContactUs";
import Products from "./pages/Products";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsConditions from "./pages/TermsConditions";
import Whitelisting from "./pages/Whitelisting";
import Logins from "./pages/Logins";
import PartnerAffiliate from "./pages/PartnerAffiliate";
import Career from "./pages/Career";
import Footer from "./components/Footer";
import ScrollToTop from "./components/ScrollToTop";
import PageNotFound from "./pages/PageNotFound";

const RouteLinks = [
  { path: "/", element: <Home /> },
  { path: "/meet-valerie", element: <MeetValerie /> },
  { path: "/meet-jm", element: <MeetJM /> },
  { path: "/contact-us", element: <ContactUs /> },
  { path: "/products", element: <Products /> },
  { path: "/privacy-policy", element: <PrivacyPolicy /> },
  { path: "/terms-of-use", element: <TermsConditions /> },
  { path: "/whitelisting", element: <Whitelisting /> },
  { path: "/about-us", element: <AboutUs /> },
  { path: "/logins", element: <Logins /> },
  { path: "/partner-and-affiliate", element: <PartnerAffiliate /> },
  { path: "/career", element: <Career /> },
];

function App() {
  const [menuActive, setMenuActive] = useState(false);

  function disableMenu() {
    setMenuActive(false);
  }

  function menuToggler() {
    setMenuActive(!menuActive);
  }

  function scrollToTop() {
    window.scrollTo(0, 0);
  }

  useLayoutEffect(() => {
    disableMenu();

    window.matchMedia("(max-width:768px)").addEventListener("change", () => {
      document.body.style.overflowY = "scroll";
      setMenuActive(false);
    });
  }, []);

  useEffect(() => {
    if (menuActive) {
      document.body.style.overflowY = "hidden";
    }
    if (!menuActive) {
      document.body.style.overflowY = "scroll";
    }
  }, [menuActive]);

  return (
    <BrowserRouter>
      <Menu menuToggler={menuToggler} menuActive={menuActive} />
      <Routes>
        {RouteLinks.map((i) => {
          return <Route key={i} path={i.path} element={i.element} />;
        })}
        <Route key="404" path="*" element={<PageNotFound />} />
      </Routes>
      <Footer />
      <ScrollToTop scrollToTop={scrollToTop} />
    </BrowserRouter>
  );
}

export default App;
