import styled from "styled-components";

export const Container = styled.div`
  padding: 10rem 0 5rem;

  .menu-bar-background {
    position: absolute;
    top: 0;
    display: block;
    height: 80px;
    width: 100vw;
    background-color: var(--bg-60);
  }
`;
export const Content = styled.div``;
