import styled from "styled-components";

export const Container = styled.div`
  background-color: var(--bg-white);
  padding: 5rem 0;
`;
export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 540px) {
    flex-direction: column;
  }

  .psd-book-image {
    width: 100%;
  }

  .psd-book-p {
    width: 50%;

    @media screen and (max-width: 540px) {
      width: 100%;

      * {
        text-align: center;
      }
    }
  }

  a.psd-button {
    display: block;
    padding: 1rem 3rem;
    background-color: var(--bg-60);
    border-radius: 20px;
    color: var(--text-white);
    margin-top: 10px;

    &:hover {
      opacity: 0.7;
    }
  }

  strong {
    font-weight: 700;
  }

  .psd-link {
    display: block;
    width: 40%;
    box-shadow: -10px 10px 20px 0 rgba(0, 0, 0, 0.2);

    @media screen and (max-width: 540px) {
      width: 60%;
      margin-bottom: 30px;
    }
  }
`;
