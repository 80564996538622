import React from "react";
import * as Components from "./styles/Content1.styled";
import Content2 from "./Content2";
import Content3 from "./Content3";

const Content1 = () => {
  return (
    <>
      <Components.Container>
        <Components.Content className="max-width-1080px">
          <div className="grid">
            <div className="spread-30">
              <h2>meet</h2>
              <h1>
                JM <span>Tetreault</span>
              </h1>
              <img
                src="https://images.dowell-ht.com/images/react-dowell-ht/image-017.png"
                alt="/"
                className="valerie-image mobile"
              />
              <p>
                Since his youth, Jean-Michel (JM) has had an unwavering passion
                for assisting others.
              </p>
              <p>
                Upon joining the military in 2006, JM soon realized his talents
                in helping injured brothers in combat – no matter whether it be
                a physical or mental ailment, from broken arms to PSTD.
              </p>
              <p>
                This experience, alongside his own traumatic events during
                childhood, enabled JM to support his wife in addressing and
                recovering from C-PTSD – in addition to coaching and mentoring
                fellow officers while in the Canadian military.
              </p>
              <p>
                He has since led the re-organization of start-ups and
                established businesses alike, including the technology sector
                and public service institutions. JM applies practicality and
                analytics to every situation, streamlining each level of a
                company for maximum efficiency.
              </p>
              <p>
                As the co-founders of DO Well, JM and his wife aid clients in
                unlocking their inner potential. JM specializes in promoting
                house organization and decluttering as effective ways to address
                trauma-based fears and start enjoying life once again.
              </p>
            </div>
            <img
              src="https://images.dowell-ht.com/images/react-dowell-ht/image-017.png"
              alt="/"
              className="valerie-image desktop"
            />
          </div>
        </Components.Content>
        <Content2 />
        <Content3 />
      </Components.Container>
    </>
  );
};

export default Content1;
