import styled from "styled-components";

export const Container = styled.div`
  padding: 10rem 0 5rem;

  h1 {
    color: var(--text-60);
    position: relative;
    transform: translateX(20px);

    &::before {
      content: "";
      position: absolute;
      left: -20px;
      height: 100%;
      width: 10px;
      background-color: var(--bg-10);
    }
  }

  .menu-bar-background {
    position: absolute;
    top: 0;
    display: block;
    height: 80px;
    width: 100vw;
    background-color: var(--bg-60);
  }

  .grid {
    display: grid;
    grid-column-gap: 30px;
    grid-row-gap: 30px;
    margin-top: 30px;

    @media screen and (min-width: 480px) {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  .login-card {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;
export const Content = styled.div`
  h2 {
    font-size: 1.65rem;
    margin-bottom: 20px;
  }

  p {
    text-align: center;
    margin-top: 20px;
  }

  a {
    display: block;
    padding: 0.5rem 3rem;
    background-color: var(--bg-10);
    margin-top: 10px;
    font-size: 1.25rem;
    font-weight: 500;
    cursor: pointer;

    &:hover {
      color: var(--bg-white);
      background-color: var(--bg-60);
    }
  }
`;
