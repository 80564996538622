import styled from "styled-components";

export const Container = styled.div`
  padding: 7rem 0;
  background-image: url(https://images.dowell-ht.com/images/react-dowell-ht/bg-001.png);
  background-position: center;
  background-size: cover;
`;
export const Content = styled.div`
  div {
    &.heading {
      display: flex;
      align-items: center;
      > * {
        color: var(--text-white);
      }
    }
    &:first-of-type {
      margin-bottom: 5rem;
    }
  }

  a {
    display: inline-block;
  }

  h1 {
    font-size: clamp(3rem, 10vw, 4rem);
    line-height: 80%;
  }

  img {
    max-height: 400px;
    margin: 0 auto;
    margin-top: 30px;
  }

  span {
    display: inline-block;
    &.rectangle {
      height: 3rem;
      width: 1rem;
      background-color: var(--bg-10);
      margin-right: 10px;
    }
    &.rectangle-2 {
      height: 3rem;
      min-width: 1rem;
      background-color: var(--bg-10);
      margin-right: 10px;
    }
  }

  .grid {
    display: grid;
    background-color: var(--bg-white);
    border-radius: 10px;
    grid-auto-flow: dense;
    overflow: hidden;
    box-shadow: 5px 5px 20px 10px rgba(0, 0, 0, 0.1);
    @media screen and (min-width: 768px) {
      grid-template-columns: 0.6fr 1fr;
    }
  }

  .reverse {
    @media screen and (min-width: 768px) {
      grid-template-columns: 1fr 0.6fr;
      img {
        grid-column-start: 2;
      }
    }
  }

  .new {
    position: relative;
  }

  .new h2::before {
    position: static;
    content: "NEW";
    color: var(--text-10);
    font-style: italic;
    margin-right: 10px;
  }

  .spread-30 {
    > *:not(:last-child) {
      margin-bottom: 30px;
    }
  }

  .word-content {
    padding: 2rem;

    h2 {
      font-size: clamp(1.25rem, 10vw, 2rem);
      line-height: 125%;
    }
    > div {
      display: flex;
      align-items: center;
    }
  }

  a.button {
    width: 100%;
    font-size: 1rem;
    color: var(--text-white);
    &:hover {
      opacity: 0.7;
    }
  }
`;
