import styled from "styled-components";

export const Container = styled.div`
  padding: 10rem 0 5rem;
  background-image: url(https://images.dowell-ht.com/images/react-dowell-ht/bg-001.png);
  background-position: center;
  background-size: cover;
`;
export const Content = styled.div`
  h1,
  h2,
  p,
  span {
    color: var(--text-white);
  }

  h1 {
    font-size: clamp(5rem, 10vw, 10rem);
    line-height: 80%;
    margin-bottom: 4rem !important;
    > span {
      position: relative;
      &::after {
        content: "";
        position: absolute;
        bottom: -0.5rem;
        right: 0;
        height: 0;
        width: 0;
        border: 30px solid transparent;
        border-top: 25px solid var(--bg-white);
        border-bottom: 0;
      }
    }
  }

  h2 {
    font-weight: var(--light);
    font-size: clamp(2rem, 5vw, 3rem);
    margin-bottom: 0px !important;
  }

  img {
    &.mobile {
      @media screen and (min-width: 768px) {
        display: none;
      }
    }
    &.desktop {
      position: sticky;
      top: 0;
      @media screen and (max-width: 768px) {
        display: none;
      }
    }
  }

  strong {
    font-weight: var(--bold);
    color: var(--text-white);
  }

  u {
    color: var(--text-white);
  }

  .spread-30 {
    > *:not(:last-child) {
      margin-bottom: 30px;
    }
  }

  .grid {
    display: grid;
    grid-gap: 30px;
    @media screen and (min-width: 768px) {
      grid-template-columns: repeat(2, 1fr);
    }
  }
`;
