import React from "react";
import * as Components from "./styles/Content3.styled";
import Content4 from "./Content4";
import ProductsDisplay from "../ProductsDisplay";

const ProductArray = [
  {
    image: `https://images.dowell-ht.com/images/react-dowell-ht/image-004.png`,
    name: "Put That Stuff Down: The Book",
    description:
      "Our 112-page book teaches readers about the fundamentals of PTSD, how it can manifest in everyday life, and actions they can take to overcome these obstacles.",
    button: "Buy the book",
    key: 1,
    url: "https://go.dowellht.com/put-that-stuff-down-paperback-book1/",
  },
  {
    image: `https://images.dowell-ht.com/images/react-dowell-ht/image-005.png`,
    name: "Put That Stuff Down: The Program",
    description:
      "Featuring online courses and live Q&A calls every month via Zoom, clients enjoy learning about and receiving ongoing support at every stage of their decluttering journey.",
    button: "Learn more",
    key: 2,
    url: "/products",
  },
  {
    image: `https://images.dowell-ht.com/images/react-dowell-ht/image-006.png`,
    name: "Post Trauma Secrets & Decluttering: The Podcast",
    description:
      "Hosted by Valerie Huard and JM Tetreault, Post Trauma Secrets & Decluttering brings you on a journey from trauma to Joie de Vivre!",
    button: "Access the Podcast",
    key: 3,
    url: "https://podcasts.apple.com/ca/podcast/post-trauma-secrets-decluttering/id1552770563",
  },
];

const Content3 = () => {
  return (
    <>
      <Components.Container>
        <Components.Content className="max-width-1080px">
          <h2>WHAT YOU WILL GET HERE?</h2>
          <img
            src="https://images.dowell-ht.com/images/react-dowell-ht/image-015.png"
            alt="/"
            className="hero box-shadow"
          />
          <div className="grid">
            <div>
              <p>
                I was studying Occupational Therapy in university and I was not
                even aware that Professional Organizing was something …
              </p>
              <p>
                Through a long series of events I understand that I have C-PTSD
                (Complex-Post-Traumatic Stress Disorder) and{" "}
                <strong>
                  started taking care of myself, of my family, of my house.
                </strong>
              </p>
              <p>
                I ended up getting certified as a Home Stager to realize
                majority of my clients needed help primarily in their
                decluttering and organizing prior to any home staging.
              </p>
            </div>
            <div>
              <p>
                While working with my clients,
                <strong>
                  I discovered the interconnection between the clutter, and
                  messy house with surviving traumatic event.
                </strong>
              </p>
              <p>
                I started researching to find out there are scientific
                correlation between having a tendency to holding to stuff and
                suffering a trauma.
              </p>
              <p>
                I realized that when I shared my story about trauma and clutter
                it gave me the chance to{" "}
                <strong>connect and help people,</strong> through that
                vulnerability, that are experiencing the same shame, pain or
                overwhelming feeling.
              </p>
            </div>
          </div>
          <Content4 />
          <ProductsDisplay ProductArray={ProductArray} />
        </Components.Content>
      </Components.Container>
    </>
  );
};

export default Content3;
