import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  padding: 7rem 0;
  background-image: url(https://images.dowell-ht.com/images/react-dowell-ht/bg-001.png);
  background-position: center;
  background-size: cover;

  .gmail-phone {
    display: none;
    position: absolute;
    right: 0;
    top: 5rem;
    width: 40%;
    @media screen and (min-width: 768px) {
      display: block;
    }
  }
`;
export const Content = styled.div`
  h1 {
    font-size: clamp(2rem, 5vw, 4rem);
    color: var(--text-white);
  }
  h2 {
    color: var(--text-white);
    font-weight: var(--light);
  }
  b {
    color: var(--text-10);
    font-weight: var(--bold);
  }
  /************************************************* classes */
  .content {
    @media screen and (min-width: 768px) {
      width: 70%;
      max-width: 700px;
    }
  }
  .spread-30 {
    > *:not(:last-child) {
      margin-bottom: 30px;
    }
  }
  .heading {
    display: flex;
    align-items: center;
  }
  .rectangle {
    display: inline-block;
    height: 3rem;
    width: 10px;
    background-color: var(--bg-10);
    margin-right: 1rem;
  }
  .text-10 {
    color: var(--text-10);
  }
  .white-bg {
    background-color: var(--bg-white);
    padding: 2rem;
    border-radius: 10px;

    h3 {
      color: var(--text-60);
      display: flex;
      align-items: center;
      font-weight: var(--light);
      font-size: 1.5rem;

      img {
        margin-right: 1rem;
      }
    }

    h4 {
      font-weight: var(--light);
      font-size: 1.25rem;
    }

    ul {
      list-style: solid;
      list-style-position: inside;
      li {
        font-size: 1.15rem;
        font-weight: var(--light);
        line-height: 200%;
      }
    }
  }
`;
