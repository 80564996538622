import React from "react";
import * as Components from "./styles/MeetJM.styled";
import Content1 from "../components/meet-jm/Content1";

const MeetJM = () => {
  return (
    <>
      <Components.Container>
        <Components.Content>
          <Content1 />
        </Components.Content>
      </Components.Container>
    </>
  );
};

export default MeetJM;
