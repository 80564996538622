import React from "react";
import * as Components from "./styles/Logins.styled";

const Logins = () => {
  const programsArray = [
    {
      name: "DOWell Programs",
      key: 1,
      image:
        "https://images.dowell-ht.com/images/dowell-programs-image-001.png",
      description: "The amazing hub with the books, and programs",
      path: "https://dowellht.customerhub.com/login",
    },
    {
      name: "Doers Academy membership",
      key: 2,
      image:
        "https://images.dowell-ht.com/images/doers-academy-membership-image-001.png",
      description:
        "The membership with the decluttering and organizing success roadmap",
      path: "https://doers.academy/login-to-your-account/",
    },
  ];

  return (
    <>
      <Components.Container>
        <span className="menu-bar-background" />
        <Components.Content className="max-width-1080px">
          <h1>Choose a program to login:</h1>
          <div className="grid">
            {programsArray.map((i) => {
              return (
                <div key={i.key} className="login-card">
                  <h2>{i.name}</h2>
                  <img src={i.image} alt="/" />
                  <p>{i.description}</p>
                  <a href={i.path}>Log in</a>
                </div>
              );
            })}
          </div>
        </Components.Content>
      </Components.Container>
    </>
  );
};

export default Logins;
