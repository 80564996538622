import styled from "styled-components";

export const Container = styled.div`
  padding: 5rem 0;
`;
export const Content = styled.div`
  display: grid;

  img {
    justify-self: center;
    width: 600px;
    max-width: 100%;
    height: auto;
  }
`;
