import styled from "styled-components";

export const Container = styled.div``;
export const Content = styled.div`
  div.products-display {
    display: grid;
    @media screen and (min-width: 768px) {
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 30px;
    }
  }
`;
