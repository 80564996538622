import styled from "styled-components";

export const Container = styled.div`
  z-index: 5;
  position: absolute;
  top: 0;
  padding: 1rem 0;
  width: 100vw;
  overflow: hidden;
`;
export const Content = styled.div`
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;

  img {
    &.logo {
      height: 50px;
      width: auto;
    }
  }
`;
export const MenuLinksContainer = styled.div`
  position: fixed;
  top: 2rem;
  left: 50%;
  z-index: 5;
  transform: translateX(-50%);
  display: ${({ menuActive }) => (menuActive ? "flex" : "none")};
  flex-direction: column;
  padding-top: 2rem;
  align-items: center;
  @media screen and (min-width: 910px) {
    display: flex;
    position: static;
    flex-direction: row;
    transform: translateX(0);
    align-items: center;
    padding-top: 0;
  }

  div {
    &.contact {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 2rem;
      @media screen and (min-width: 910px) {
        align-items: flex-end;
        margin-top: 0;
        margin-left: 2rem;
      }

      a {
        color: var(--text-10);
      }
    }
  }

  p {
    color: var(--text-60);
    font-size: 1rem;
    @media screen and (min-width: 910px) {
      color: var(--text-10);
      line-height: 150%;
    }
  }
`;
export const MenuLink = styled.a`
  color: var(--text-black) !important;
  transition: 0.2s all ease-in-out;
  animation-name: ${({ menuActive }) => menuActive && "animateIn"};
  animation-duration: 0.2s;
  &:not(:last-of-type) {
    margin-bottom: 20px;
    @media screen and (min-width: 910px) {
      margin-bottom: 0;
      margin-right: 30px;
    }
  }
  &:hover {
    opacity: 0.5;
  }
  @keyframes animateIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @media screen and (min-width: 910px) {
    color: var(--text-white) !important;
  }
`;
export const BurgerContainer = styled.div`
  position: relative;
  z-index: 20;
  div {
    &.burger-container {
      z-index: 10;
      height: 19px;
      width: 30px;
      cursor: pointer;
    }
    &.burger {
      position: absolute;
      height: 3px;
      width: 30px;
      border-radius: 10px;
      &:not(:last-of-type) {
        margin-bottom: 5px;
      }
    }
  }
  @media screen and (min-width: 910px) {
    display: none;
  }
`;
export const Circle = styled.div`
  z-index: -1;
  position: absolute;
  top: 50%;
  left: 50%;
  background: white;
  border-radius: 50%;
  transform: translate(-50%, -55%);
  animation-name: ${({ menuActive }) => menuActive && "circleIn"};
  animation-duration: 2s;
  animation-fill-mode: forwards;
  @keyframes circleIn {
    0% {
      height: 0px;
      width: 0px;
    }
    100% {
      height: 10000px;
      width: 10000px;
    }
  }
`;
export const Burger = styled.div`
  background-color: ${({ menuActive }) =>
    menuActive ? "var(--bg-60)" : "var(--bg-white)"};
  transition: 0.2s all ease-in-out;

  &:nth-of-type(1) {
    top: ${({ menuActive }) => (menuActive ? "50%" : 0)};
    transform: ${({ menuActive }) =>
      menuActive && "translateY(-50%) rotate(45deg)"};
  }
  &:nth-of-type(2) {
    top: 50%;
    transform: translateY(-50%);
    opacity: ${({ menuActive }) => (menuActive ? 0 : 1)};
  }
  &:nth-of-type(3) {
    bottom: ${({ menuActive }) => (menuActive ? "50%" : 0)};
    transform: ${({ menuActive }) =>
      menuActive && "translateY(50%) rotate(-45deg)"};
  }
`;
export const MenuLinksBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  background-color: var(--bg-white);
  height: ${({ menuActive }) => (menuActive ? "100vh" : 0)};
  width: 100vw;
  z-index: -10;
  transition: 0.1s all ease-in-out;
  transition-property: height;
  @media screen and (min-width: 910px) {
    display: none;
  }
`;
export const FixedOnMenuActive = styled.div`
  position: fixed;
  right: 2rem;
`;
