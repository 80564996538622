import React from "react";
import * as Components from "./styles/Career.styled";

const Career = () => {
  return (
    <>
      <Components.Container>
        <span className="menu-bar-background" />
        <Components.Content className="max-width-1080px">
          <h1>The DO Well Network is always interested in amazing talents.</h1>
          <div className="flex">
            <img
              src="https://images.dowell-ht.com/images/collaboration.jpg"
              alt="/"
            />
            <ul>
              <p className="underline">Talents in</p>
              <li>Coaching</li>
              <li>Professional Organizers</li>
              <li>Digital Marketing</li>
              <li>Business Support</li>
              <li>Creative</li>
              <li>Sales</li>
              <li>Data Analytics</li>
            </ul>
          </div>
          <p>
            If you have what it takes and like to work hard with an incredible
            team, <strong>Apply here</strong>, and start your introduction with
            the word purple in capital letters.
          </p>
          <form
            action="https://formsubmit.co/a38eb4a4ddff75f2f4f2fd1cb91bbc89"
            method="POST"
            className="form"
          >
            <input type="text" name="name" required placeholder="name" />
            <input type="email" name="email" required placeholder="email" />
            <input
              type="text"
              name="phone"
              required
              placeholder="phone number"
            />
            <input
              type="text"
              name="location"
              required
              placeholder="location"
            />
            <textarea
              name="message"
              placeholder="message"
              rows="5"
              col="30"
            ></textarea>
            <input
              type="hidden"
              name="_next"
              value="https://the.dowellht.com"
            />
            <input type="hidden" name="_captcha" value="false" />

            <button type="submit">Send</button>
          </form>
        </Components.Content>
      </Components.Container>
    </>
  );
};

export default Career;
