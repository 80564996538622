import styled from "styled-components";
import { IoClose } from "react-icons/io5";

export const Container = styled.div`
  z-index: 0;
  position: relative;
  padding: 10rem 0 5rem;
  background-image: url(https://images.dowell-ht.com/images/react-dowell-ht/bg-001.png);
  overflow: hidden;
  @media screen and (max-width: 520px) {
    padding: 8rem 0 2rem;
  }

  img {
    &.image-001 {
      z-index: -10;
      position: absolute;
      bottom: -200px;
      right: -80px;
      height: 430px;
      width: auto;
      max-width: 100%;
      @media screen and (min-width: 768px) {
        width: 400px;
        height: auto;
        bottom: -120px;
        right: -60px;
      }
      @media screen and (min-width: 1000px) {
        width: 550px;
        height: auto;
        right: -70px;
      }
      @media screen and (max-width: 450px) {
        width: 500px;
        height: auto;
        display: none;
      }
    }
  }

  @media screen and (min-width: 1000px) {
    padding: 8rem 0 2rem;
  }
`;
export const Content = styled.div`
  position: relative;
  z-index: 1;
  h1 {
    color: var(--text-white);
    font-size: clamp(2rem, 5vw, 2.5rem);
    font-weight: var(--light);
    line-height: 130%;
    max-width: 600px;
  }
  strong {
    color: inherit;
    font-weight: var(--bold);
  }
  p {
    color: var(--text-white);
    margin-top: 100px;
    font-size: 1.25rem;
    font-weight: var(--light);
    margin-top: 30px;
    @media screen and (min-width: 768px) {
      margin-top: 30px;
    }
  }
  div {
    &.triangle {
      height: 0;
      width: 0;
      border: 15px solid transparent;
      border-bottom: 0;
      border-top: 20px solid var(--bg-white);
      margin-top: 10px;
    }
  }
  a.button {
    color: var(--text-black);
    padding: 0.5rem 2rem;
    display: inline-block;
    border-radius: 50px;
    background-color: var(--bg-10);
    margin-top: 10px;
    text-align: center;
    max-width: 400px;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
    font-size: 1.1rem;
    transition: 0.2s all ease-in-out;
    transition-property: transform;
    &:hover {
      transform: translateY(-5px);
    }
  }
  .ghost-btn {
    display: inline-block;
    max-width: 400px;
    border: 1px solid var(--bg-white);
    border-radius: 30px;
    padding: 0.5rem 2rem;
    background-color: transparent;
    color: white;
    text-align: center;
    font-size: 1.1rem;
    transition: 0.2s all ease-in-out;
    transition-property: transform;
    &:hover {
      transform: translateY(-5px);
      color: var(--text-60);
      background-color: var(--bg-white);
    }
  }
  .btn-container {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
  }
`;
export const VideoContainer = styled.div`
  position: fixed;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 10;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.2);

  > section {
    position: relative;
    height: 100%;
    width: 100%;

    > div {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
`;
export const CloseIcon = styled(IoClose)`
  z-index: 10;
  position: absolute;
  right: 10px;
  top: 10px;
  height: 40px;
  width: auto;
  fill: var(--bg-white);
  cursor: pointer;
  &:hover {
    fill: var(--bg-10);
  }
`;
export const Container2 = styled.div`
  padding: 3rem 0;
`;
export const Content2 = styled.div`
  display: flex;
  justify-content: center;
`;
export const Container3 = styled.div`
  padding: 5rem 0;
  background-color: var(--bg-60);
  text-align: center;

  h4 {
    color: var(--text-white);
    font-size: 1.5rem;
    font-weight: 300;
    letter-spacing: 0.3rem;
  }
`;
export const Content3 = styled.div``;
export const Container4 = styled.div`
  padding: 10rem 0;
  background-image: url(https://images.dowell-ht.com/images/background-002.png);
  background-position: center;
  background-size: cover;
  overflow: hidden;

  .pencil {
    position: absolute;
    top: -10px;
    left: 50%;
    transform: rotate(180deg);
    height: 300px;
    width: auto;
    transform-origin: 0 0;

    @media screen and (max-width: 650px) {
      display: none;
    }
  }
`;
export const Content4 = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 650px) {
    flex-direction: column-reverse;
  }

  h4 {
    position: relative;
    font-size: 2rem;
    font-weight: 300;
    max-width: 650px;
    padding-right: 30px;
    color: var(--text-white);

    @media screen and (max-width: 650px) {
      padding-right: 0;
      margin-top: 50px;
    }

    &::before {
      content: "";
      position: absolute;
      top: -30px;
      left: 0;
      height: 20px;
      width: 50%;
      background-color: var(--bg-10);
    }
  }

  strong {
    color: var(--text-white);
    font-weight: 700;
  }

  a.image-link {
    display: block;
  }

  img {
    height: 100%;
    width: auto;

    @media screen and (max-width: 650px) {
      width: 100%;
      height: auto;
    }
  }
`;
export const Line = styled.span`
  display: block;
  height: 1px;
  width: 50%;
  background-color: var(--bg-60);
  margin: 0 auto;
  margin-top: 50px;
  transform: translateY(50px);
`;
