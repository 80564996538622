import React, { useState } from "react";
import * as Components from "./styles/Content1.styles";
import ReactPlayer from "react-player";

const Content1 = () => {
  const [videoOpen, setVideoOpen] = useState(false);

  function videoOpenToggler(e) {
    e.preventDefault();
    setVideoOpen(!videoOpen);
  }

  return (
    <>
      <Components.Container>
        <Components.Content className="max-width-1080px">
          <h1>
            Empowering Over 100,000 People To Overcome Their{" "}
            <strong>Clutter and Trauma </strong>
            Through The <strong>Decluttering & Organizing Journey</strong>
          </h1>
          <div className="btn-container">
            <a href="/" className="ghost-btn" onClick={videoOpenToggler}>
              Learn More
            </a>
            <a
              href="https://try.dowellht.com/get-the-art-of-letting-go-blueprint?el=dowellhtmaintop"
              className="button"
            >
              Get Your Free Art Of Letting Go Blueprint
            </a>
          </div>
          <img
            src="https://images.dowell-ht.com/images/react-dowell-ht/jm-and-valerie-01.png"
            alt="/"
            className="image-001"
          />
        </Components.Content>
      </Components.Container>
      {videoOpen && (
        <Components.VideoContainer>
          <Components.CloseIcon onClick={videoOpenToggler} />
          <section>
            <div>
              <ReactPlayer
                url="https://vimeo.com/727577894/c3915c51ae"
                width="80vw"
                height="80vh"
                controls="true"
              />
            </div>
          </section>
        </Components.VideoContainer>
      )}
      <Components.Container2>
        <Components.Content2 className="max-width-1080px">
          <img
            src="https://images.dowell-ht.com/images/credibility-bar-002.png"
            alt="credibility-bar"
            className="credibility-bar"
          />
        </Components.Content2>
      </Components.Container2>
      <Components.Container3>
        <Components.Content className="max-width-1080px">
          <h4>
            JOIN OVER <strong>15 THOUSAND EMPOWERED MEMBERS</strong>
          </h4>
        </Components.Content>
      </Components.Container3>
      <Components.Container4>
        <Components.Content4 className="max-width-1080px">
          <h4>
            <img
              src="https://images.dowell-ht.com/images/pencil-01.png"
              alt="/"
              className="pencil"
            />
            A <strong>proven path</strong> and <strong>checklist</strong> to
            help keep you through the essentials of a{" "}
            <strong>successful decluttering and organizing journey</strong>
          </h4>
          <a
            href="https://try.dowellht.com/get-the-art-of-letting-go-blueprint?el=dowellhtmain2"
            className="image-link"
          >
            <img
              src="https://images.dowell-ht.com/images/promo-01-01.png"
              alt="/"
            />
          </a>
        </Components.Content4>
        {/* <Components.Line /> */}
      </Components.Container4>
    </>
  );
};

export default Content1;
