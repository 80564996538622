import styled from "styled-components";

export const Container = styled.div`
  padding: 10rem 0 5rem;

  .menu-bar-background {
    position: absolute;
    top: 0;
    display: block;
    height: 80px;
    width: 100vw;
    background-color: var(--bg-60);
  }

  .form {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
  }

  .flex {
    display: flex;
    justify-content: space-between;
    margin: 30px 0;

    @media screen and (max-width: 768px) {
      flex-direction: column;
    }

    img {
      width: 40%;
      height: 300px;
      object-fit: cover;
      object-position: center;

      @media screen and (max-width: 768px) {
        width: 100%;
        margin-bottom: 10px;
      }
    }

    ul {
      width: 50%;
    }
  }
`;
export const Content = styled.div`
  h1 {
    color: var(--text-60);
    position: relative;
    transform: translateX(30px);

    &::before {
      content: "";
      position: absolute;
      left: -30px;
      height: 100%;
      width: 10px;
      background-color: var(--bg-10);
    }
  }

  p {
    &.underline {
      text-decoration: underline;
    }
  }

  ul {
    margin-bottom: 30px;
  }

  li {
    list-style-position: inside;
    font-family: "Poppins", sans-serif;
    font-size: 1.1rem;
    font-weight: 300;
  }

  strong {
    font-weight: 700;
  }

  input {
    padding: 0.5rem 1rem;
    margin-bottom: 10px;
    border: 1px solid var(--text-black);
    font-family: "Poppins", sans-serif;
  }

  textarea {
    font-family: "Poppins", sans-serif;
    padding: 1rem;
    font-size: 1rem;
    resize: none;
    margin-bottom: 30px;
  }

  button {
    width: 50%;
    padding: 1rem;
    border: none;
    border-radius: 0;
    background-color: var(--bg-10);
    font-family: "Poppins", sans-serif;
    font-size: 1.1rem;
    font-weight: 700;
    cursor: pointer;

    &:hover {
      background-color: var(--bg-60);
      color: var(--text-white);
    }

    @media screen and (max-width: 768px) {
      width: 100%;
    }
  }
`;
