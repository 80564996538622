import styled from "styled-components";

export const Container = styled.div`
  padding: 8rem 0 5rem;
  background-image: url(https://images.dowell-ht.com/images/react-dowell-ht/bg-001.png);
  background-position: center;
  background-size: cover;

  * {
    color: var(--text-white);
  }

  .meet {
    display: flex;
    align-items: center;
    gap: 30px;
  }

  .meet-image {
    height: 200px;
    width: auto;
  }
`;
export const Content = styled.div`
  h1,
  h2 {
    position: relative;
    transform: translateX(40px);
    margin-bottom: 30px;

    &::before {
      content: "";
      position: absolute;
      height: 100%;
      width: 20px;
      left: -40px;
      background-color: var(--bg-10);
    }
  }

  p {
    margin-bottom: 30px;
  }

  h4 {
    font-size: 1.5rem;
  }

  a {
    color: var(--text-10);
    text-decoration: underline;

    &.learn-more {
      display: block;
      margin-top: -30px;
    }
  }
`;
