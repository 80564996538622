import React from "react";
import ProductsDisplay from "../ProductsDisplay";

const ProductArray = [
  {
    image: `https://images.dowell-ht.com/images/react-dowell-ht/image-004.png`,
    name: "Put That Stuff Down: The Book",
    description:
      "Our 112-page book teaches readers about the fundamentals of PTSD, how it can manifest in everyday life, and actions they can take to overcome these obstacles.",
    button: "Buy the book",
    key: 1,
    url: "https://go.dowellht.com/put-that-stuff-down-paperback-book1/",
  },
  {
    image: `https://images.dowell-ht.com/images/react-dowell-ht/image-005.png`,
    name: "Put That Stuff Down: The Program",
    description:
      "Featuring online courses and live Q&A calls every month via Zoom, clients enjoy learning about and receiving ongoing support at every stage of their decluttering journey.",
    button: "Learn more",
    key: 2,
    url: "/products",
  },
  {
    image: `https://images.dowell-ht.com/images/react-dowell-ht/image-006.png`,
    name: "Post Trauma Secrets & Decluttering: The Podcast",
    description:
      "Hosted by Valerie Huard and JM Tetreault, Post Trauma Secrets & Decluttering brings you on a journey from trauma to Joie de Vivre!",
    button: "Access the Podcast",
    key: 3,
    url: "https://podcasts.apple.com/ca/podcast/post-trauma-secrets-decluttering/id1552770563",
  },
];

const Content3 = () => {
  return (
    <>
      <div className="max-width-1080px">
        <ProductsDisplay ProductArray={ProductArray} />
      </div>
    </>
  );
};

export default Content3;
