import styled from "styled-components";

export const Container = styled.div`
  padding: 5rem 0;
  background-image: url(https://images.dowell-ht.com/images/react-dowell-ht/bg-003.png);
  background-position: center;
  background-size: cover;
`;
export const Content = styled.div`
  display: grid;
  grid-gap: 30px;

  > div {
    > *:not(:last-child) {
      margin-bottom: 30px;
    }
  }

  span {
    &.rectangle {
      display: block;
      height: 20px;
      width: 80%;
      background-color: var(--bg-10);
      margin-bottom: 10px;
    }
  }

  h2 {
    font-size: clamp(2rem, 5vw, 3rem);
    line-height: 100%;
    max-width: 450px;
    color: var(--text-60);
  }

  p {
    font-size: 1.15rem;
    font-weight: var(--light);
  }
  strong {
    font-weight: var(--bold);
  }

  h3 {
    font-size: clamp(2rem, 5vw, 3rem);
    color: var(--text-10);
    line-height: 100%;
  }

  @media screen and (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 30px;
  }
`;
