import React from "react";
import * as Components from "./styles/Content1.styled";
// import ProductsDisplay from "../ProductsDisplay";
import { VscTriangleRight } from "react-icons/vsc";

// const ProductArray = [
//   {
//     image: `https://images.dowell-ht.com/images/react-dowell-ht/image-004.png`,
//     name: "Put That Stuff Down: The Book",
//     description:
//       "Our 112-page book teaches readers about the fundamentals of PTSD, how it can manifest in everyday life, and actions they can take to overcome these obstacles.",
//     button: "Buy the book",
//     key: 1,
//     url: "https://go.dowellht.com/put-that-stuff-down-paperback-book1/",
//   },
//   {
//     image: `https://images.dowell-ht.com/images/react-dowell-ht/image-005.png`,
//     name: "Put That Stuff Down: The Program",
//     description:
//       "Featuring online courses and live Q&A calls every month via Zoom, clients enjoy learning about and receiving ongoing support at every stage of their decluttering journey.",
//     button: "Learn more",
//     key: 2,
//     url: "/products",
//   },
//   {
//     image: `https://images.dowell-ht.com/images/react-dowell-ht/image-006.png`,
//     name: "Post Trauma Secrets & Decluttering: The Podcast",
//     description:
//       "Hosted by Valerie Huard and JM Tetreault, Post Trauma Secrets & Decluttering brings you on a journey from trauma to Joie de Vivre!",
//     button: "Access the Podcast",
//     key: 3,
//     url: "https://podcasts.apple.com/ca/podcast/post-trauma-secrets-decluttering/id1552770563",
//   },
// ];

const socialsArray = [
  {
    name: "Facebook Page",
    subname: "Dowellht",
    link: "https://www.facebook.com/dowellht",
    image: "https://images.dowell-ht.com/images/facebook-icon.png",
    key: 1,
  },
  {
    name: "Instagram",
    subname: "dowell.life",
    link: "https://www.instagram.com/dowell.life/",
    image: "https://images.dowell-ht.com/images/instagram-icon.png",
    key: 2,
  },
  {
    name: "LinkedIn",
    subname: "Dowellht",
    link: "https://www.linkedin.com/company/dowell-ht",
    image: "https://images.dowell-ht.com/images/linkedin-icon.png",
    key: 3,
  },
  {
    name: "Youtube Channel",
    subname: "Post Trauma Secrets and Decluttering",
    link: "https://www.youtube.com/c/PostTraumaSecretsDeclutteringbyDOWell",
    image: "https://images.dowell-ht.com/images/youtube-icon.png",
    key: 4,
  },
  {
    name: "Podcast",
    subname: "Post Trauma Secrets and Decluttering",
    link: "https://posttraumasecretsdecluttering.buzzsprout.com/",
    image: "https://images.dowell-ht.com/images/podcast-icon.png",
    key: 5,
  },
  {
    name: "Pinterest",
    subname: "Dowellht",
    link: "https://www.pinterest.com/dowellht",
    image: "https://images.dowell-ht.com/images/pinterest-icon.png",
    key: 6,
  },
];

const Content1 = () => {
  return (
    <>
      <Components.Container>
        <Components.Content className="max-width-1080px">
          <div className="grid">
            <img
              src="https://images.dowell-ht.com/images/react-dowell-ht/image-018.png"
              alt="/"
            />
            <div className="spread-30 word-content">
              <span className="rectangle" />
              <h1>contact us</h1>
              <h2 style={{ display: "flex", alignItems: "center" }}>
                <VscTriangleRight
                  style={{
                    height: "3rem",
                    width: "auto",
                    fill: "var(--bg-white)",
                    marginRight: "10px",
                  }}
                />
                We are looking forward to have a conversation with you...
              </h2>
              <ul className="spread-30">
                <form
                  action="https://formsubmit.co/a38eb4a4ddff75f2f4f2fd1cb91bbc89"
                  method="POST"
                  className="form"
                >
                  <input type="text" name="name" placeholder="Name" required />
                  <input
                    type="email"
                    name="email"
                    placeholder="Email Address"
                    required
                  />
                  <textarea
                    name="message"
                    placeholder="Enter your message here"
                    rows="5"
                    col="30"
                  />
                  <input
                    type="hidden"
                    name="_next"
                    value="https://the.dowellht.com"
                  />
                  <input type="hidden" name="_captcha" value="false" />
                  <button type="submit" className="submitBtn">
                    Send
                  </button>
                </form>
                <li>Use the chat box below to talk with us 24 / 7</li>
                <li>
                  Phone:{" "}
                  <u>
                    <a href="tel:1-888-770-5556">1-888-770-5556</a>
                  </u>{" "}
                  Monday to Friday 9 to 5pm EST, or leave a message if we’re
                  answering another customer
                </li>
              </ul>
            </div>
          </div>
        </Components.Content>
        <div className="max-width-1080px socials">
          {/* <ProductsDisplay ProductArray={ProductArray} /> */}

          <h2>Follow Us on Social</h2>
          <div className="socials-container">
            {socialsArray.map((i) => {
              return (
                <div className="social-container" key={i.key}>
                  <a href={i.link} className="social-link">
                    <img src={i.image} alt="/" />
                  </a>

                  <a href={i.link} className="social-link">
                    <h4>{i.name}</h4>
                  </a>
                  <p>{i.subname}</p>
                </div>
              );
            })}
          </div>
        </div>
      </Components.Container>
    </>
  );
};

export default Content1;
