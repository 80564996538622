import React from "react";
import * as Components from "./styles/DoersAcademy.styled";

const DoersAcademy = () => {
  return (
    <>
      <Components.Container>
        <Components.Content className="max-width-1080px">
          <div className="da-words">
            <p className="da-intro">We have something new for you!</p>
            <h3>DOers Academy</h3>
            <p>
              The Academy is the leading training community for people who want
              to let go of trauma & clutter for good.
            </p>
            <p>
              <strong>
                It's a membership to help you get a peaceful home and create an
                amazing life!
              </strong>
            </p>
            <p>
              It’s packed with in-depth, practical training and resources on all
              aspects of decluttering the stress, mind, time, and space so you
              can live the amazing life you deserve.{" "}
            </p>
            <p>
              PLUS the community offers the support you need to ensure you
              achieve your full potential.
            </p>
            <a
              href="https://try.dowellht.com/doersacademy?el=dowellhtmain1"
              className="button"
            >
              Join now!
            </a>
          </div>
          <a href="https://try.dowellht.com/doersacademy?el=dowellhtmain1">
            <img
              src="https://images.dowell-ht.com/images/da-image-001.png"
              alt="/"
              className="da-image"
            />
          </a>
        </Components.Content>
      </Components.Container>
    </>
  );
};

export default DoersAcademy;
