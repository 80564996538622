import React from "react";
import * as Components from "./styles/Content3.styled";
// import ProductsDisplay from "../ProductsDisplay";
import Testimonials from "./Testimonials";

// const ProductArray = [
//   {
//     image: `https://images.dowell-ht.com/images/react-dowell-ht/image-004.png`,
//     name: "Put That Stuff Down: The Book",
//     description:
//       "Our 112-page book teaches readers about the fundamentals of PTSD, how it can manifest in everyday life, and actions they can take to overcome these obstacles.",
//     button: "Buy the book",
//     key: 1,
//     url: "https://go.dowellht.com/put-that-stuff-down-paperback-book1/",
//   },
//   {
//     image: `https://images.dowell-ht.com/images/react-dowell-ht/image-005.png`,
//     name: "Put That Stuff Down: The Program",
//     description:
//       "Featuring online courses and live Q&A calls every month via Zoom, clients enjoy learning about and receiving ongoing support at every stage of their decluttering journey.",
//     button: "Learn more",
//     key: 2,
//     url: "/products",
//   },
//   {
//     image: `https://images.dowell-ht.com/images/react-dowell-ht/image-006.png`,
//     name: "Post Trauma Secrets & Decluttering: The Podcast",
//     description:
//       "Hosted by Valerie Huard and JM Tetreault, Post Trauma Secrets & Decluttering brings you on a journey from trauma to Joie de Vivre!",
//     button: "Access the Podcast",
//     key: 3,
//     url: "https://podcasts.apple.com/ca/podcast/post-trauma-secrets-decluttering/id1552770563",
//   },
// ];

const socialsArray = [
  {
    name: "Facebook Page",
    subname: "Dowellht",
    link: "https://www.facebook.com/dowellht",
    image: "https://images.dowell-ht.com/images/facebook-icon.png",
    key: 1,
  },
  {
    name: "Instagram",
    subname: "dowell.life",
    link: "https://www.instagram.com/dowell.life/",
    image: "https://images.dowell-ht.com/images/instagram-icon.png",
    key: 2,
  },
  {
    name: "LinkedIn",
    subname: "Dowellht",
    link: "https://www.linkedin.com/company/dowell-ht",
    image: "https://images.dowell-ht.com/images/linkedin-icon.png",
    key: 3,
  },
  {
    name: "Youtube Channel",
    subname: "Post Trauma Secrets and Decluttering",
    link: "https://www.youtube.com/c/PostTraumaSecretsDeclutteringbyDOWell",
    image: "https://images.dowell-ht.com/images/youtube-icon.png",
    key: 4,
  },
  {
    name: "Podcast",
    subname: "Post Trauma Secrets and Decluttering",
    link: "https://posttraumasecretsdecluttering.buzzsprout.com/",
    image: "https://images.dowell-ht.com/images/podcast-icon.png",
    key: 5,
  },
  {
    name: "Pinterest",
    subname: "Dowellht",
    link: "https://www.pinterest.com/dowellht",
    image: "https://images.dowell-ht.com/images/pinterest-icon.png",
    key: 6,
  },
];

const Content3 = () => {
  return (
    <>
      <Components.Container>
        <Components.Content className="max-width-1080px">
          <div>
            <div className="rectangle"></div>
            <h2>Addressing Trauma, One Piece of Clutter at a Time</h2>
          </div>
          <div className="content3-content">
            <img
              src="https://images.dowell-ht.com/images/react-dowell-ht/image-003.png"
              alt="/"
            />
            <div>
              <p>
                <strong>
                  Have you ever realized the mess in your home and committed
                  yourself to organizing it, yet never seemed to manage to get
                  the task done?
                </strong>
              </p>
              <p>
                The reason for this is not because you are lazy, nor is it down
                to you not caring about the tidiness of where you live. Instead,
                it is likely due to unresolved trauma.
              </p>
              <p>
                <strong>
                  Following extensive research around the link between trauma
                  and clutter, DO Well has launched a range of products and
                  services, all with a single overarching goal in mind: to help
                  clients fall back in love with their homes and their lives.
                </strong>
              </p>
            </div>
          </div>
        </Components.Content>
        <Testimonials />
        <Components.Content className="max-width-1080px socials">
          {/* <ProductsDisplay ProductArray={ProductArray} /> */}

          <h2>Follow Us on Social</h2>
          <div className="socials-container">
            {socialsArray.map((i) => {
              return (
                <div className="social-container" key={i.key}>
                  <a href={i.link} className="social-link">
                    <img src={i.image} alt="/" />
                  </a>

                  <a href={i.link} className="social-link">
                    <h4>{i.name}</h4>
                  </a>
                  <p>{i.subname}</p>
                </div>
              );
            })}
          </div>
        </Components.Content>
      </Components.Container>
    </>
  );
};

export default Content3;
