import React from "react";
import styled from "styled-components";

const TermsConditions = () => {
  return (
    <>
      <Container>
        <Content className="max-width-1080px spread-30">
          <div className="heading">
            <span className="rectangle" />
            <h1>Terms of Use</h1>
          </div>
          <div className="content spread-30">
            <p>TERMS OF USE</p>
            <p>Last updated: Feb 26, 2021</p>
            <p>
              Welcome to http://dowell-ht.com/ and https://dowellht.com (the
              “Website”). We, being DO Well, division of Tetreault Huard Group
              inc. and/or its affiliates (the “Company”) own and operate the
              Website and are providing internet services to you subject to the
              following conditions. If you use, visit or shop at the Website,
              you accept these terms and conditions (“Terms”), so please read
              them carefully. This electronic document is considered to be a
              legally binding and enforceable contract between you the Company.
              By using, visiting, or shopping at the Website you acknowledge
              that you are aware of and completely accept all of the terms and
              conditions of these Terms.
            </p>
            <p>
              These Terms are divided into THREE (3) sections, being:
              <br />
              General Terms for All Users;
              <br />
              Terms for Subscribers; and,
              <br />
              Code of Conduct.
            </p>
          </div>
          <div className="content spread-30">
            <h2>SECTION 1 – GENERAL TERMS FOR ALL USERS</h2>
            <p>
              Users include everyone who visits the Website, and includes those
              who have registered to view, enjoy and otherwise use the services
              offered by Website, whether for free or on a paid basis. Paid
              Users are referred to as “Subscribers”. All Users are subject to
              the General Terms set forth in this Section 1. All Users must
              agree and conform to the Code of Conduct set forth in Section 3.
              All Users are subject to the Privacy Policy set forth in Section
              4. Electronic Communications
            </p>
            <p>
              When you visit the Website, send e-mails to us, message us, or
              otherwise communicate with us, you are communicating with us
              electronically, and therefore you consent to receive
              communications from us electronically in return. We will
              communicate with you by e-mail, messaging or by posting notices on
              this site. You agree that all agreements, notices, disclosures and
              other communications that we provide to you electronically satisfy
              any legal requirement that such communications be in writing.
            </p>
            <h3>
              <u>Copyright</u>
            </h3>
            <p>
              All Content included on this site, such as video clips, video
              streams, text, graphics, logos, button icons, images, audio clips,
              digital downloads, data compilations, and software, is the
              property of the Company or its content suppliers and protected by
              Canadian and international copyright laws. The compilation of all
              content on this site is also the exclusive property of the Company
              and protected by Canadian and international copyright laws. All
              software used on this site is the property of the Company or its
              software suppliers and protected by Canadian and international
              copyright laws.
            </p>
            <h3>
              <u>Trademarks</u>
            </h3>
            <p>
              “DO Well” and other Website graphics, logos, page headers, button
              icons, scripts, and service names are trademarks, registered
              trademarks or trade dress of the Company in Canada. The Company’s
              trademarks and trade dress may not be used in connection with any
              product or service that is not the Company’s, in any manner that
              is likely to cause confusion among customers, or in any manner
              that disparages or discredits the Company. All other trademarks
              not owned by the Company that appear on this site are the property
              of their respective owners, who may or may not be affiliated with,
              connected to, or sponsored by the Company. License and Site Access
            </p>
            <p>
              The Company grants you a limited license to access and make
              personal use of this site but not to download (other than page
              caching) or modify it, or any portion of it. This license does not
              include any resale or commercial use of this site or its contents;
              nor any collection and use of any product listings, descriptions,
              or prices; nor any derivative use of this site or its contents;
              nor any downloading or copying of Account information for the
              benefit of another merchant; nor any use of data mining, robots,
              or similar data gathering and extraction tools. This site or any
              portion of this site may not be reproduced, duplicated, copied,
              sold, resold, visited, or otherwise exploited for any commercial
              purpose without express written consent of the Company. You may
              not frame or utilize framing techniques to enclose any trademark,
              logo, or other proprietary information (including images, text,
              page layout, or form) of the Company without express written
              consent. You may not use any meta-tags or any other “hidden text”
              utilizing the Company’s name or trademarks without the express
              written consent of the Company. Any unauthorized use terminates
              the permission or license granted by the Company. You are granted
              a limited, revocable, and nonexclusive right to create a hyperlink
              to the home page of the Website so long as the link does not
              portray the Company, or its products or services in a false,
              misleading, derogatory, or otherwise offensive matter. You may not
              use any the Company logo or other proprietary graphic or trademark
              as part of the link without express written permission.
            </p>
            <h3>
              <u>
                Hyperlinking To Website, Co-Branding, “Framing” And Referencing
                Website Prohibited
              </u>
            </h3>
            <p>
              Unless expressly authorized by Website, no one may hyperlink this
              Website, or portions thereof, (including, but not limited to,
              logotypes, trademarks, branding or copyrighted material) to any
              other website for any reason. Further, Users are not allowed to
              reference the url (website address) of this Website in any
              commercial or non-commercial media without express permission, nor
              are Users allowed to ‘frame’ the Website. The User specifically
              agrees to cooperate with the Website to remove or de-activate any
              such activities and be liable for all damages.
            </p>
            <h3>
              <u>Use Of Information From This Website</u>
            </h3>
            <p>
              Unless you have entered into an express written contract with this
              Website to the contrary, Users are not permitted to use the
              information contained in the Website (the “Information”) in a
              commercial or public setting or to broadcast, copy, save, print,
              teach, sell, coach, or publish the Information or any portions of
              the content of this Website. By viewing the contents of this
              Website Users agree to this condition of viewing and Users
              acknowledge that any unauthorized use is unlawful and may subject
              you to civil or criminal penalties. The User warrants that the
              User understands that accepting this provision is a condition of
              viewing the Website and that viewing constitutes acceptance.
            </p>
            <h3>
              <u>Comments, Communications, and Other Content</u>
            </h3>
            <p>
              If you submit comments, communications or other content, you grant
              the Company a nonexclusive, royalty-free, perpetual, irrevocable,
              and fully sub-licensable right to use, reproduce, modify, adapt,
              publish, translate, create derivative works from, distribute, and
              display such information or other content throughout the world in
              any media. You grant the Company and sub-licensees the right to
              use the name that you submit in connection with such information
              or other content, if they choose. You represent and warrant that
              you own or otherwise control all of the rights to the information
              or other content that you send or post; that the information or
              other content is accurate; that use of the information or other
              content you supply does not violate this policy and will not cause
              injury to any person or entity; and that you will indemnify the
              Company for all claims resulting from information or other content
              you supply. The Company has the right but not the obligation to
              monitor and edit or remove any information or other content. The
              Company takes no responsibility and assumes no liability for any
              information or other content posted by you or any third party.
            </p>
            <h3>
              <u>Copyright and Other Legal Rights Complaints</u>
            </h3>
            <p>
              The Company respects the intellectual property and legal rights of
              others. If you believe that intellectual property or other legal
              rights have been violated, please provide us with the following
              information: (i) an electronic or physical signature of the person
              authorized to act on behalf of the rightsholder; (ii) a
              description of the alleged rights violation; (iii) a description
              of where the alleged violating material is located on the Website;
              (iv) your address, telephone number, and e-mail address; (v) a
              written statement by you that you have a good faith belief that
              the disputed use is not authorized by the rightsholder, or the
              law; (vi) a statement by you that the above information in your
              notice is accurate and that you are the actual rightsholder or
              authorized to act on the copyright owner’s behalf. Contact
              information for notice of claims of rights violation is the
              following questions@dowell-ht.com. We will process your complaint
              pursuant to the Copyright Act of Canada.
            </p>
            <h3>
              <u>Product Descriptions</u>
            </h3>
            <p>
              The Company attempts to be as accurate as possible. However, the
              Company does not warrant that product descriptions or other
              content of this site is accurate, complete, reliable, current, or
              error-free. If a product offered by the Company itself is not as
              described, your sole legal remedy is to return it in unused
              condition.
            </p>
            <h3>
              <u>Other Businesses</u>
            </h3>
            <p>
              We may provide links to the sites of affiliated companies and
              certain other businesses. We are not responsible for examining or
              evaluating, and we do not warrant the offerings of, any of these
              businesses or individuals or the content of their websites. The
              Company does not assume any responsibility or liability for the
              actions, product, and content of all these and any other third
              parties. You should carefully review their privacy statements and
              other conditions of use.
            </p>
            <h3>
              <u>Members of the Media</u>
            </h3>
            <p>
              As a User, you certify that you are not a member of the media, or
              that if you are, you are participating on this Website solely for
              your personal enjoyment and not as part of any investigation or
              gathering of information for dissemination of information to the
              public as news and that you will not use any such information in
              any manner without the express written permission of the Company.
              You agree that everything that you observe on the Website will be
              kept in strict confidence, and that you will do nothing to
              jeopardize the privacy and identities of any other User
              whatsoever.
            </p>
            <p>
              Disclaimer of Warranties, Limitation of Liability and
              Indemnification THIS SITE AND ALL INFORMATION, CONTENT, MATERIALS,
              PRODUCTS (INCLUDING SOFTWARE) AND SERVICES INCLUDED ON OR
              OTHERWISE MADE AVAILABLE TO YOU THROUGH THIS SITE ARE PROVIDED BY
              THE WEBSITE ON AN “AS IS” AND “AS AVAILABLE” BASIS, UNLESS
              OTHERWISE SPECIFIED IN WRITING. THE WEBSITE MAKES NO
              REPRESENTATIONS OR WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, AS
              TO THE OPERATION OF THIS SITE OR THE INFORMATION, CONTENT,
              MATERIALS, PRODUCTS (INCLUDING SOFTWARE) OR SERVICES INCLUDED ON
              OR OTHERWISE MADE AVAILABLE TO YOU THROUGH THIS SITE, UNLESS
              OTHERWISE SPECIFIED IN WRITING. YOU EXPRESSLY AGREE THAT YOUR USE
              OF THIS SITE IS AT YOUR SOLE RISK.
            </p>
            <p>
              TO THE FULL EXTENT PERMISSIBLE BY APPLICABLE LAW, THE WEBSITE
              DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, BUT NOT
              LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR
              A PARTICULAR PURPOSE. THE WEBSITE DOES NOT WARRANT THAT THIS SITE;
              INFORMATION, CONTENT, MATERIALS, PRODUCTS (INCLUDING SOFTWARE) OR
              SERVICES INCLUDED ON OR OTHERWISE MADE AVAILABLE TO YOU THROUGH
              THIS SITE; THEIR SERVERS; OR E-MAIL SENT FROM THE WEBSITE ARE FREE
              OF VIRUSES OR OTHER HARMFUL COMPONENTS. THE WEBSITE WILL NOT BE
              LIABLE FOR ANY DAMAGES OF ANY KIND ARISING FROM THE USE OF THIS
              SITE, INCLUDING BUT NOT LIMITED TO DOWNLOADING ANY FILES OR
              INFORMATION FROM THE WEBSITE OR FROM ANY INFORMATION, CONTENT,
              MATERIALS, PRODUCTS (INCLUDING SOFTWARE) OR SERVICES INCLUDED ON
              OR OTHERWISE MADE AVAILABLE TO YOU THROUGH THIS SITE, INCLUDING,
              BUT NOT LIMITED TO DIRECT, INDIRECT, INCIDENTAL, PUNITIVE, AND
              CONSEQUENTIAL DAMAGES, UNLESS OTHERWISE SPECIFIED IN WRITING.
            </p>
            <p>
              THE WEBSITE FURTHER DISCLAIMS ANY RESPONSIBILITY FOR THE ACCURACY
              OF THE CONTENT OF THIS WEBSITE. EXAMPLES AND TESTIMONIALS IN THESE
              MATERIALS ARE NOT TO BE INTERPRETED AS A PROMISE OR GUARANTEE OF
              RESULTS. RESULT POTENTIAL IS ENTIRELY DEPENDENT ON THE PERSON
              USING OUR PRODUCTS, THEIR IDEAS AND TECHNIQUES. THIS PRODUCT IS
              NOT A THERAPY AND ONLY PROVIDES ADVICE AND TRAINING ABOUT
              WELLNESS. THIS IS A NEW PRODUCT AND SYSTEM AND AS SUCH THERE IS NO
              HISTORY OF RESULTS FROM ITS USE. WE DO NOT TRACK ACTUAL RESULTS OF
              USERS OF OUR PRODUCT AS THE SAME WOULD VIOLATE THE USERS PRIVACY
              AND CONFIDENTIAL OR PROPRIETARY INFORMATION. THE INFORMATION ON
              THIS SITE IS OUR EXPERIENCE WITH THE PRODUCT. IF YOU WANT TO SHARE
              YOUR EXPERIENCE LET US KNOW.
            </p>
            <p>
              BY VIEWING, USING, OR INTERACTING IN ANY MANNER WITH THIS WEBSITE,
              INCLUDING ANY BANNERS, ADVERTISING, OR POP-UPS, DOWNLOADS, AND AS
              A CONDITION OF THE WEBSITE ALLOWING USER’S LAWFUL VIEWING, USER
              FOREVER WAIVES ALL RIGHT TO CLAIMS OF DAMAGE OF ANY AND ALL
              DESCRIPTION DUE TO USER’S USE OF THE WEBSITE AND BASED ON ANY
              CAUSAL FACTOR RESULTING IN ANY POSSIBLE HARM, NO MATTER HOW
              HEINOUS OR EXTENSIVE, WHETHER PHYSICAL OR EMOTIONAL, FORESEEABLE
              OR UNFORESEEABLE, WHETHER PERSONAL OR BUSINESS IN NATURE.
            </p>
            <p>
              USER AGREES THAT IN THE EVENT USER CAUSES DAMAGE WHICH THE WEBSITE
              IS REQUIRED TO PAY FOR, THE USER, AS A CONDITION OF VIEWING, WILL
              REIMBURSE THE WEBSITE FOR ANY AND ALL COSTS RELATING TO SUCH
              DAMAGE, INCLUDING WITHOUT LIMITATION ANY LEGAL FEES.
            </p>
            <h3>
              <u>Applicable Law</u>
            </h3>
            <p>
              By visiting the Website, you agree that the laws of the Province
              of Ontario, Canada, without regard to principles of conflict of
              laws, will govern these Terms and any dispute of any sort that
              might arise between you and the Company, and any disputes shall be
              adjudicated in the City of Ottawa, Ontario, Canada, and you
              consent to exclusive jurisdiction and venue in such courts.
            </p>
            <h3>
              <u>Site Policies, Modification, and Severability</u>
            </h3>
            <p>
              Please review our other policies as posted on this Website. We
              reserve the right to make changes to the Website, policies, and
              these Terms at any time. If any of these conditions shall be
              deemed invalid, void, or for any reason unenforceable, that
              condition shall be deemed severable and shall not affect the
              validity and enforceability of any remaining condition.
            </p>
          </div>
          <div className="content spread-30">
            <h2>SECTION 2 – TERMS FOR SUBSCRIBERS</h2>
            <p>
              This Section applies to all Subscribers and their Accounts
              (“Accounts”), which may also be referred to as “Subscriptions”.
            </p>
            <h3>
              <u>Your Account</u>
            </h3>
            <p>
              You agree to keep all of your Account information current (such as
              a change in billing address, card number, or expiration date), and
              you must promptly notify the Company if your payment method is
              canceled (including if you lose your card or it is stolen), or if
              you become aware of a potential breach of security (such as an
              unauthorized disclosure or use of your name or password). Changes
              to such information can be made on the Website. If you fail to
              provide us any of the foregoing information, you agree that you
              are responsible for fees accrued under your Account. In addition,
              you authorize us to obtain updated or replacement expiration dates
              and card numbers for your credit or debit card as provided by your
              credit or debit card issuer.
            </p>
            <p>
              You agree that all payments made on the Website are final, and you
              will not challenge or dispute the charge with your bank. You
              further agree that should you have any issues relating to your
              payment (such as duplicate billing), you will contact the Company
              to resolve the issue. Should a chargeback or dispute be initiated
              with your bank, you agree that you will be held responsible for
              any outstanding balance owed to us plus any chargeback fees
              charged by our bank, which may be as much as $60 CAD. You agree
              that you will pay any outstanding balance you have with the
              Company within 30 days from the date of notification. Any
              outstanding balance left unpaid after 30 days may be submitted to
              a collections agency, and you agree that a collections fee of up
              to 50% of the outstanding balance or $100 (whichever is higher)
              will be added to the amount that you owe.
            </p>
            <p>
              In order to provide continuous service, the Company provides the
              option for automatic renewal. If the automatic renewal option or
              checkbox is selected during the payment process, we will
              automatically renew all paid Subscriptions on the date such
              Subscriptions expire. After your initial Subscription commitment
              period, and again after any subsequent Subscription period, your
              Subscription will automatically continue for an additional
              equivalent period. You acknowledge that your Account will be
              subject to these automatic renewals if you had selected the
              automatic renewal option. If you do not wish your Account to renew
              automatically, or if you want to change or terminate your
              Subscription, please log in and go to “Account Settings” on the
              Website and follow the directions contained therein.
            </p>
            <p>
              The Company maintains a strict no-refund policy. All purchases
              made on the Website are final. If you choose to purchase a
              Subscription package on the Website, you agree that absolutely no
              refunds, either full or partial, will be issued for any reasons or
              for any unused or remaining Subscription. Further, you agree that
              no refunds will be made on any remaining or unused Subscription or
              credits. Subscriptions purchased are strictly non-transferable.
            </p>
            <p>
              The Company reserves the right to offer free Subscriptions to any
              person(s) of its choice at any given time, for any duration, while
              charging other Subscribers, at the same time. We also reserve the
              right to cancel the free trial period at any time, for all or any
              of its Subscribers. Upon registering as a Subscriber, you will
              choose a display name and password. You may not select or use a
              display name of another person (unless it is also your name), a
              name in violation of a third party’s property rights, or a display
              name that the Company deems offensive or otherwise inappropriate.
              The Company owns all display names and licenses them to you. We
              reserve the right to delete or alter any display name at any time.
            </p>
            <p>
              You are entirely liable for all activities conducted through your
              Account. A Subscriber may not permit another individual to use the
              Subscriber’s Account without direct supervision by the Subscriber.
            </p>
            <p>
              Subscribers who have had their Account terminated may not access
              the Website without the Company’s prior express written
              permission. Subscribers may not allow a former Subscriber whose
              Account has been terminated to use their Account. For additional
              information on re-registration and multiple Accounts you can
              contact us at cs@dowell-ht.com.
            </p>
            <p>
              Your bank or financial institution may impose fees for currency
              exchange and for international transactions. You are responsible
              for all such costs.
            </p>
            <p>
              The Company may apply additional taxes as required by law in all
              regions. For billing inquiries, or to request a detailed statement
              of your usage, contact us at cs@dowell-ht.com.
            </p>
            <p>
              THE COMPANY RESERVES THE RIGHT, AT ANY TIME, TO CHANGE ITS FEES
              AND BILLING METHODS, INCLUDING THE ADDITION OF SUPPLEMENTAL FEES
              OR SEPARATE CHARGES FOR ANY ONLINE AREAS, CONTENT, PREMIUM
              SUBSCRIPTIONS, OR SERVICES PROVIDED BY THE COMPANY OR ITS
              AFFILIATES. THE COMPANY MAY ALSO ELECT, AT ITS DISCRETION, TO
              SUPPLEMENT SUCH NOTICE OF BILLING CHARGES THROUGH POP-UPS, EMAIL,
              MEMBER INBOX ON SITE, THROUGH THE MAIL TO THE ACCOUNT HOLDER, OR
              BY ANY OTHER MEANS DEEMED REASONABLE BY THE COMPANY.
            </p>
            <p>
              If any such change is unacceptable to you, you may terminate your
              Account as provided below. Your continued use of the Website
              following the effective date shall constitute your acceptance of
              such change.
            </p>
            <h3>
              <u>Restriction on Resale of Company Products</u>
            </h3>
            <p>
              The Company encourages all Subscribers to use any of our products,
              information and knowledge that Subscribers receive through their
              use of the Website (the “Products”) to sell third-party products
              online. While the Company wishes financial success upon all of our
              Subscribers, to protect our own business, we specifically prohibit
              the resale of any of our Products or the sale of any products
              created by Subscriber that are based on, derived from or
              substantially similar to any of the Products (the “Resale
              Policy”). The Subscriber agrees to abide by the Resale Policy and
              agrees that the Company shall have the right to pursue any
              available legal remedy against the Subscriber upon a breach by
              Subscriber of the Resale Policy
            </p>
            <h3>
              <u>Representations and Warranties</u>
            </h3>
            <p>
              You certify that YOU ARE AT LEAST NINETEEN (19) YEARS OLD or of
              the Age of Majority in the jurisdiction that you are domiciled in,
              whichever is older. You agree to provide the Company with
              accurate, complete, and current Account information. Failure to do
              this shall constitute a breach of these Terms and will result in
              immediate termination of your Account and subject you to civil and
              possible criminal liability. Unless you have prior written
              authorization from the Company, you may not register for
              additional Accounts after such termination.
            </p>
            <h3>
              <u>Cancellation and Termination</u>
            </h3>
            <p>
              Subscribers may cancel their Account at any time by sending
              written consent to us with your account username to
              cs@dowell-ht.com. This is your sole right if you are dissatisfied
              with the Website in any way.
            </p>
            <p>
              When you delete your Account or when your Account is suspended for
              violating these Terms, you agree that any remaining or unused
              Subscription fees and credits will be forfeited and no refunds
              will be issued for any remaining or unused Subscription fees and
              credits. Further, if your deleted Account is reinstated at a later
              date, you agree that any remaining or unused Subscription fees and
              credits you have remaining at the time of your Account deletion
              will not be reinstated with your Account.
            </p>
            <p>
              Furthermore, the Company reserves the right to terminate any
              Accounts arbitrarily. We also reserve the right to deny service
              for Accounts and users deemed as fraudulent or otherwise high risk
              Accounts. Processing is at our discretion for refunds for claims
              of fraud and/or disputes via email, web chat with support, phone
              calls to our processor and/or requests from issuing banks, banks
              offering direct debit, card holders, and credit card issuers.
            </p>
            <p>
              We also report at our discretion and cooperate with law
              enforcement in any claims of fraud or other illegal activity.
            </p>
          </div>
          <div className="content spread-30">
            <h2>SECTION 3 – CODE OF CONDUCT</h2>
            <p>
              All Users, including but not limited to Subscribers, hereby agree
              NOT to use the Website for any of the following: Posting any
              incomplete, false, misleading, or inaccurate Content about
              yourself and/or your profile;
            </p>
            <p>
              Impersonating, or otherwise misrepresenting affiliation,
              connection or association with, any person or entity;
            </p>
            <p>
              If you have a password, allowing any other person to access a
              non-public area of the Website, disclosing to or sharing your
              password with any third parties, or using your password for any
              unauthorized purpose;
            </p>
            <p>
              Use meta tags or code or other devices containing any reference to
              the Company or the Website (or any trademark, trade name, service
              mark, logo or slogan of the Company or the Website) to direct any
              person to any other website for any purpose;
            </p>
            <p>
              Posting or sending material that depicts people in a sexual or
              violent manner, or solicits personal information from anyone under
              the age of majority in that person’s jurisdiction of domicile;{" "}
            </p>
            <p>
              Soliciting passwords or personal identifying information for
              commercial or unlawful purposes from other users;
            </p>
            <p>
              Engaging in advertising to, or solicitation of, other Users to
              send money, buy or sell any products or services not approved by
              the Company;
            </p>
            <p>Transmitting any chain letters or junk email;</p>
            <p>
              Soliciting gambling or engaging in any gambling or similar
              activity;
            </p>
            <p>
              Posting advertisements or solicitations of employment or business;
            </p>
            <p>
              Using the Website to promote, solicit, or engage in prostitution;
            </p>
            <p>
              Using the Website or Service for activities that violate any law,
              statute, ordinance, or regulations;
            </p>
            <p>
              Using the Website or Service to encourage, promote, facilitate, or
              instruct others to engage in illegal activity;
            </p>
            <p>
              Personally attack, make fun of, troll, flame, bully, stalk or
              otherwise harass another User;
            </p>
            <p>Make criminal accusations against another User;</p>
            <p>
              Make or promote any type of racism or hate towards anyone in
              specific or a group of people;
            </p>
            <p>
              Post, directly or indirectly, any personally identifying
              information about another member without their consent.
              “Personally identifying information” can include, but is not
              limited to, a person’s full name, first name, last name, email
              address, profession, phone number, address and place of work;
            </p>
            <p>Use the Website for any fraudulent purposes;</p>
            <p>
              Collect or store personally identifying information about any
              other User(s) for commercial purposes;
            </p>
            <p>
              Impersonate any person or entity, including, but not limited to, a
              Company official, employee, consultant, or otherwise, or falsely
              state or otherwise misrepresent your affiliation with a person or
              entity;
            </p>
            <p>
              Upload, post, email, otherwise transmit, or post links to any
              Content that you do not have a right to transmit under any law or
              regulation or under contractual or fiduciary relationships (such
              as “inside information”, or proprietary and confidential
              information learned or disclosed as part of employment
              relationships or subject to a nondisclosure agreement);
            </p>
            <p>
              Upload, post, email, or otherwise transmit, or post links to any
              Content that facilitates computer hacking;
            </p>
            <p>
              Upload, post, email, otherwise transmit, or post links to any
              content that infringes any patent, trademark, service mark, trade
              secret, copyright or other proprietary rights of any party, or
              contributing to inducing or facilitating such infringement,
              including making available tools that can be used for no purpose
              other than for “cracking” software or other copyrighted content;
            </p>
            <p>
              Purchase any goods or services that you are prohibited from
              purchasing or possessing by any law applicable to you in your
              jurisdiction. The responsibility for ensuring compliance with all
              such laws shall be the User’s alone. By submitting an order to
              purchase goods or services, you represent and warrant that you
              have the legal right to purchase such goods or services; and
            </p>
            <p>
              Advertise, offer for sale, or sell any of the following items:
            </p>
            <p>Any firearms, explosives, or weapons;</p>
            <p>
              Any items that are hateful or racially, ethnically or otherwise
              objectionable, that contain child pornography, or are harmful to
              those under the age of majority;
            </p>
            <p>Any controlled substances or pharmaceuticals;</p>
            <p>Any counterfeit or stolen items;</p>
            <p>Any goods or services that do not, in fact, exist;</p>
            <p>Any registered or unregistered securities;</p>
            <p>
              Any items that violate or infringe the rights of other parties;
            </p>
            <p>Any items that you do not have the legal right to sell; and</p>
            <p>
              Any items where paying the Company any of the required
              transactional or listing fees would cause the Company to violate
              any law.
            </p>
            <p>
              You further agree that you will not harass, annoy, intimidate, or
              threaten any of the Company’s employees, affiliates or agents.
            </p>
          </div>
        </Content>
      </Container>
    </>
  );
};

export default TermsConditions;

// Styled Components
const Container = styled.div`
  padding: 10rem 0 5rem;
  background-image: url(https://images.dowell-ht.com/images/react-dowell-ht/bg-001.png);
  background-size: cover;
  background-position: center;

  .spread-30 {
    > *:not(:last-child) {
      margin-bottom: 30px;
    }
  }
`;
const Content = styled.div`
  div {
    &.heading {
      display: flex;
      align-items: center;
    }
    &.content {
      padding: 5rem;
      background-color: var(--bg-white);
      border-radius: 10px;
    }
  }

  span {
    &.rectangle {
      display: inline-block;
      height: 5rem;
      width: 1rem;
      background-color: var(--bg-10);
      margin-right: 10px;
    }
  }

  h1 {
    font-size: clamp(3rem, 10vw, 5rem);
    color: var(--text-white);
    line-height: 100%;
  }

  p {
    font-size: 1.2rem;
  }

  ul,
  li {
    font-size: 1.2rem;
    list-style: none;
    font-weight: var(--light);
  }
`;
