import React from "react";
import * as Components from "./styles/Content1.styled";

const Content1 = () => {
  return (
    <>
      <Components.Container>
        <Components.Content className="max-width-1080px ">
          <div className="spread-30">
            <div className="heading">
              <span className="rectangle" />
              <h1>whitelisting</h1>
            </div>
            <div className="content spread-30">
              <h2>How To Never Miss An Email From the DO Well</h2>
              <p className="text-10">
                You want to <b>whitelist</b> all emails from: cs@dowell-ht.com
                and for them to be in your primary inbox.
              </p>
              <div className="white-bg spread-30">
                <h3>
                  <img
                    src="https://images.dowell-ht.com/images/react-dowell-ht/gmail-icon.png"
                    alt="/"
                  />
                  Gmail
                </h3>
                <h4>
                  Here’s How To move the DO Well emails to your Gmail Primary
                  tab{" "}
                </h4>
                <ul>
                  <li>
                    Drag the email from "DO Well", likely in your Promotions
                    tab, to your Primary tab
                  </li>
                  <li>
                    To the question: “Do this for future messages?” click “Yes”
                  </li>
                </ul>
                <p>
                  It is also possible to whitelist before opening the email:
                  hover over the sender’s name, next to the subject line, and
                  select “Add to contacts”, which will save this email address
                  to your Contacts list.
                </p>
                <ul>
                  <li>Open the email in your inbox</li>
                  <li>Hover over the sender’s name</li>
                  <li>
                    Select “Add to contacts” to save the sender to your Contacts
                    list
                  </li>
                </ul>
                <p>Whitelist emails from cs@dowell-ht.com</p>
              </div>
              <div className="white-bg">
                <p>
                  For the other Email Providers, you can add the DO Well email
                  address to your contact. This should whitelist for many
                  providers. You can also verify with your provider.
                </p>
              </div>
            </div>
          </div>
        </Components.Content>
        <img
          src="https://images.dowell-ht.com/images/react-dowell-ht/gmail-phone.png"
          alt="/"
          className="gmail-phone"
        />
      </Components.Container>
    </>
  );
};

export default Content1;
