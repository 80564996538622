import React from "react";
import * as Components from "./styles/PartnerAffiliate.styled";

const PartnerAffiliate = () => {
  return (
    <>
      <Components.Container>
        <span className="menu-bar-background" />
        <Components.Content className="max-width-1080px">
          <h1>The DO Well Network Partners and Affiliates</h1>
          <p>
            We are always looking for new partners and create win-win
            situations.
          </p>
        </Components.Content>
      </Components.Container>
    </>
  );
};

export default PartnerAffiliate;
