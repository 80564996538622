import React from "react";
import * as Components from "./styles/Content4.styled";

const Content4 = () => {
  return (
    <>
      <Components.Container>
        <Components.Content className="max-width-1080px">
          <div className="content-container grid">
            <div className="content-container">
              <h2>JOIN THE COMMUNITY</h2>
              <p>
                By connecting with us on Facebook, you can start moving forward
                with escaping the trauma ‘prison’ and finally let go of clutter
                once and for all.
              </p>
              <a
                href="https://www.facebook.com/groups/270205454634828"
                className="button"
              >
                Join the Group
              </a>
            </div>
            <img
              src="https://images.dowell-ht.com/images/react-dowell-ht/image-011.png"
              alt="/"
            />
          </div>
          <div className="content-container grid">
            <div className="content-container">
              <h2>GET IN TOUCH</h2>
              <p>
                If you would like to learn more about DO Well, from the
                organizing process to what we have to offer, please reach out
                today!
              </p>
              <a href="/contact-us" className="button">
                Contact Us
              </a>
            </div>
            <img
              src="https://images.dowell-ht.com/images/react-dowell-ht/image-012.png"
              alt="/"
            />
          </div>
        </Components.Content>
      </Components.Container>
    </>
  );
};

export default Content4;
