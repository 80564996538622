import React from "react";
import * as Components from "./styles/ProductsDisplay.styled";
import Products from "./Products";

const ProductsDisplay = ({ ProductArray }) => {
  return (
    <>
      <Components.Container>
        <Components.Content>
          {/* <hr
            style={{
              border: "transparent",
              borderTop: "solid 2px white",
              width: "80%",
              margin: "0 auto",
              marginBottom: "50px",
            }}
          ></hr> */}
          <div className="products-display">
            {ProductArray.map((i) => {
              return (
                <Products
                  image={i.image}
                  key={i.key}
                  name={i.name}
                  description={i.description}
                  button={i.button}
                  url={i.url}
                />
              );
            })}
          </div>
        </Components.Content>
      </Components.Container>
    </>
  );
};

export default ProductsDisplay;
