import styled from "styled-components";

export const Container = styled.div`
  padding: 5rem 0;
  background-image: url(https://images.dowell-ht.com/images/react-dowell-ht/bg-002.png);
  background-position: center;
  background-size: cover;
`;
export const Content = styled.div`
  > *:not(:last-child) {
    margin-bottom: 30px;
  }
  div {
    &.grid {
      display: grid;
      grid-gap: 30px;
      > div > *:not(:last-child) {
        margin-bottom: 30px;
      }
      @media screen and (min-width: 768px) {
        width: 700px;
        margin: 0 auto;
      }
      > div {
        @media screen and (min-width: 768px) {
          display: flex;
          flex-direction: column;
        }
      }
    }
  }
  h2 {
    font-size: clamp(2rem, 5vw, 3rem);
    color: var(--text-white);
    line-height: 100%;
    @media screen and (min-width: 768px) {
      text-align: center;
    }
  }
  img {
    &.hero {
      height: 200px;
      width: 100%;
      max-width: 100%;
      object-position: center;
      object-fit: cover;
    }
  }
  p,
  strong {
    font-size: 1.15rem;
    font-weight: var(--light);
    color: var(--text-white);
  }
  strong {
    font-weight: var(--bold);
  }
`;
