import React from "react";
import * as Components from "./styles/Menu.styled";

import Logo from "../images/logo-001.png";

const MenuLinks = [
  { name: "blog", path: "https://library.dowellht.com", key: "blog" },
  // { name: "meet Valerie", path: "meet-valerie", key: "meetValerie" },
  // { name: "meet JM", path: "meet-jm", key: "meetJM" },
  // { name: "contact us", path: "contact-us", key: "contactUs" },
  { name: "about us", path: "about-us", key: "aboutUs" },
  {
    name: "resources",
    path: "https://library.dowellht.com/resources",
    key: "resources",
  },
  { name: "products", path: "products", key: "products" },
  {
    name: "logins",
    path: "logins",
    key: "logins",
  },
];

const Menu = ({ menuToggler, menuActive }) => {
  return (
    <>
      <Components.Container>
        <Components.Content className="max-width-1080px">
          <Components.MenuLinksBackground menuActive={menuActive} />
          <a href="/">
            <img src={Logo} alt="logo" className="logo" />
          </a>
          <Components.MenuLinksContainer menuActive={menuActive}>
            {MenuLinks.map((i) => {
              return (
                <Components.MenuLink
                  menuActive={menuActive}
                  key={i.key}
                  href={i.path}
                >
                  {i.name}
                </Components.MenuLink>
              );
            })}
            <div className="contact">
              <p>
                <a href="tel:1-888-770-5556">1-888-770-5556</a>{" "}
              </p>
            </div>
          </Components.MenuLinksContainer>

          <Components.BurgerContainer>
            <div className="burger-container" onClick={menuToggler}>
              <Components.Burger
                menuActive={menuActive}
                className="burger "
              ></Components.Burger>
              <Components.Burger
                menuActive={menuActive}
                className="burger "
              ></Components.Burger>
              <Components.Burger
                menuActive={menuActive}
                className="burger"
              ></Components.Burger>
            </div>
            {/* <div className="circle-container">
              <Components.Circle menuActive={menuActive} />
            </div> */}
          </Components.BurgerContainer>
        </Components.Content>
      </Components.Container>
    </>
  );
};

export default Menu;
