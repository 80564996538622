import React from "react";
import * as Components from "./styles/Content2.styled";

const Content2 = () => {
  return (
    <>
      <Components.Container>
        <Components.Content className="max-width-1080px">
          <div>
            <span className="rectangle" />
            <h2>
              The desperation and fear and mess in my house and shame I’ve lived
              during these times in my life were just too much..
            </h2>
          </div>
          <div>
            <img
              src="https://images.dowell-ht.com/images/react-dowell-ht/image-014.png"
              alt="/"
            />
            <p>
              <strong>
                BUT… I also know what it’s like to go through and feel way
                better.
              </strong>
            </p>
            <p>
              I know how empowering it feels
              <strong>
                {" "}
                when you take back the control of your life and decide what you
                want in it.
              </strong>{" "}
              And finally learn that it is possible to recover and discover how
              much it is worth it.
            </p>
            <p>
              I know the freedom of understanding that regular exercises,
              routines and mindfulness help you re-energize and sleep better.
              And with more energy the fulfillment of being able to maintain a
              tidy house.
            </p>
            <p>
              I know how relieving it feels when you finally get organized and
              learn that your space reflects your mind{" "}
              <strong>
                -- a cleared and organized home is the reflection of a strong
                and free mind.
              </strong>
            </p>
            <p>
              <strong>I know the freedom</strong> of adding meaningful life
              balance in our time practice, of having good routines and of
              discovering how much time you can save when you are well
              organized.
            </p>
            <p>
              I know what it feels like to finally{" "}
              <strong>learn to control your anxiety </strong>
              and to accept the part you cannot change so you are able to
              discover the world.
            </p>
            <p>
              I know the sensation to start <strong>putting you first</strong>{" "}
              and to show yourself more love, empathy and respect - that
              self-care is not selfish and even more is the beginning of opening
              yourself to others.
            </p>
            <p>
              I know that{" "}
              <strong>
                when we are well supported in our effort to start doing
                something we are able to achieve
              </strong>{" "}
              that task we were thinking impossible and we are able to give
              everyone else around the best of you...
            </p>
            <h3>… not only your living trauma</h3>
          </div>
        </Components.Content>
      </Components.Container>
    </>
  );
};

export default Content2;
