import React from "react";
import * as Components from "./styles/Content1.styled";

const Content5 = () => {
  return (
    <>
      <Components.Container>
        <Components.Content className="max-width-1080px">
          <div className="grid">
            <div className="spread-30">
              <h2>meet</h2>
              <h1>
                Valerie <span>Huard</span>
              </h1>
              <img
                src="https://images.dowell-ht.com/images/react-dowell-ht/image-013.png"
                alt="/"
                className="valerie-image mobile"
              />
              <p>
                I know what it’s like to experience horrifying events, survive
                it and{" "}
                <strong>feeling that you lose control of your life.</strong>
              </p>
              <p>
                I know what it’s like to have{" "}
                <strong>Zero energy, difficulty sleeping</strong> because of the
                nightmares and in consequence not apt in doing any chores.
              </p>
              <p>
                I know how difficult it is to be triggered into{" "}
                <strong>flashbacks</strong> and being so{" "}
                <strong>anxious</strong> that you are isolated in your home.
              </p>
              <p>
                know what it feels when people are telling you “just go over
                it”, but you are not capable of doing so, then it just makes you
                angrier and you start{" "}
                <strong>negative and diminishing self-talk.</strong>
              </p>
              <p>
                I know what it’s like to be sitting on the couch,{" "}
                <strong>not able to get up to achieve anything,</strong> even if
                you really desire a tidy place, because you are paralyzed not
                knowing where to start.
              </p>
              <p>
                I know what it’s like to see{" "}
                <strong>piles and piles of papers, dishes and stuff,</strong>{" "}
                and not being able to clean and organize them.
              </p>
              <p>
                I know what it’s like to go from one medical appointment to the
                other, to taxi kids around, to try to work few hours and to{" "}
                <strong>
                  have no time for yourself and to take care of the home.
                </strong>
              </p>
              <p>
                I know what it feels like to be in each of these situations,
                because <u>I’ve been in every Single One.</u>
              </p>
            </div>
            <img
              src="https://images.dowell-ht.com/images/react-dowell-ht/image-013.png"
              alt="/"
              className="valerie-image desktop"
            />
          </div>
        </Components.Content>
      </Components.Container>
    </>
  );
};

export default Content5;
