import React from "react";
import * as Components from "./styles/Product.styled";

const Products = ({ image, name, description, button, url }) => {
  return (
    <>
      <Components.Container>
        <Components.Content>
          <img src={image} alt="/" className="product-image" />
          <div className="product-desc-container">
            <h3>{name}</h3>
            <p>{description}</p>
            <a href={url} className="button">
              {button}
            </a>
          </div>
        </Components.Content>
      </Components.Container>
    </>
  );
};

Products.defaultProps = {
  image: "/",
  name: "DOWell",
  description: "hello",
  button: "hi",
  url: "/",
};

export default Products;
