import React from "react";
import styled from "styled-components";
import Content1 from "../components/whitelisting/Content1";

const Whitelisting = () => {
  return (
    <>
      <Container>
        <Content>
          <Content1 />
        </Content>
      </Container>
    </>
  );
};

export default Whitelisting;

// Styled Components
const Container = styled.div``;
const Content = styled.div``;
