import styled from "styled-components";

export const Container = styled.div`
  padding: 5rem 0;
`;
export const Content = styled.div`
  > div {
    &:not(:last-of-type) {
      margin-bottom: 100px;
    }
  }
  div {
    &.content-container {
      > *:not(:last-child) {
        margin-bottom: 30px;
      }
    }
    &.testimonial-grid {
      display: grid;
      gap: 10px;
      @media screen and (min-width: 768px) {
        grid-template-columns: repeat(3, 1fr);
      }
    }
    &.grid {
      display: grid;
      @media screen and (min-width: 768px) {
        grid-template-columns: repeat(2, 1fr);
        gap: 30px;
      }
    }
  }
  h2 {
    font-size: clamp(2rem, 5vw, 3rem);
    line-height: 100%;
    font-weight: var(--light);
    max-width: 800px;
    letter-spacing: 0.2rem;
  }
  p {
    font-size: 1.15rem;
    font-weight: var(--light);
    max-width: 800px;
  }
  a {
    display: inline-block;
    box-sizing: border-box;
    width: 100%;
    max-width: 300px;
    color: var(--text-white);
    &:hover {
      opacity: 0.8;
    }
  }
`;
