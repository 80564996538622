import React from "react";
import * as Components from "./styles/Content1.styled";
import ProductsDisplay from "../ProductsDisplay";

// const OurProducts = [
//   {
//     key: "paperback",
//     class: "grid",
//     name: "Paperback",
//     description:
//       "Put That Stuff Down takes its readers on a powerful journey to find peace of mind, gain healthier time practice, fully enjoy your life into an organized and welcoming home — all that to finally cope with PTSD. First, it introduces you to powerful techniques to declutter the mind from all the stresses, negative thoughts, and negative self-beliefs. Then it shows you proven methods to organize your environment at home and office. Once your environment is more welcoming and organized, you’ll see how it can really help you strengthen your mindset and clear your mind. Lastly, the book shows you how to reach your mindfulness and overcome your stress and anxiety, so you can fully enjoy your life with complete peace of mind—free from all the painful past memories!",
//     button: "Buy the Book",
//     image:
//       "https://images.dowell-ht.com/images/react-dowell-ht/put-that-stuff-down-book.png",
//     link: "https://go.dowellht.com/put-that-stuff-down-paperback-book1/",
//   },
//   // {
//   //   key: "tgc",
//   //   class: "reverse grid",
//   //   name: "Total Game Changing",
//   //   description:
//   //     "This program is the core of the method Put That Stuff Down. It is giving you access to a lot more resources and real-life examples, as well as practical tools and advice you can apply immediately. Joining this program will give you access to 4 segments that represent our proven 4-Step Decluttering Journey. The proven strategy that we personally use to transform the chaos in our life, home, and mind to peace, love, and happiness. The program is supported with a strong community, a platform to follow along and weekly live questions and answers group call with one of our coach experts for 6 months.",
//   //   button: "Join the Program",
//   //   image:
//   //     "https://images.dowell-ht.com/images/react-dowell-ht/put-that-stuff-down-changing-package.png",
//   //   link: "https://go.dowellht.com/total-game-changing-package1/",
//   // },
//   {
//     key: "academy",
//     class: "reverse grid",
//     name: "DOers Academy",
//     description: `The DOers Academy is the leading training community for people who want to let go of trauma & clutter for good. It's a membership to help you get a peaceful home and create an amazing life! The Academy was built from the process we developed that now helped over 1000 people to live the happy and amazing life they deserve. And we seriously love spending our days supporting our members.`,
//     button: "Join the Academy!",
//     image:
//       "https://images.dowell-ht.com/images/react-dowell-ht/da-sp-graphics.png",
//     link: "https://try.dowellht.com/doersacademy/",
//   },
//   {
//     key: "ssm",
//     class: "grid",
//     name: "Secrets to Save Money from Decluttering",
//     description:
//       "One of the reasons people, that come to us, want to declutter is because they want to stop wasting and want to save money. That is why we built the secrets to save money from decluttering program. Look, we’d love to tell you all the features and benefits of this program, but it’d be a long list. So here’s the brief overview: All-Important Financial Decision discussed, Budget and Expense Template,  Track your expenses and cash flow to improve your budgeting, Essential Grocery List, Comparison between a healthy grocery, healthy, Healthy Meal Prep, Elements of a fresh and healthy menu so you cut down on take-out ordering, Turn your stuff into money - How to effectively sell your unnecessary stuff on ebay, FB marketplace, craigslist and more, Smart Waste Management - Better cleaning products, cleaning service cost etc ... And so much more!",
//     button: "Join the Program",
//     image:
//       "https://images.dowell-ht.com/images/react-dowell-ht/DW-SSM-Mockup.png",
//     link: "https://go.dowellht.com/the-moneysecrets-from-decluttering1/",
//   },
//   {
//     key: "summit",
//     class: "reverse grid",
//     name: "Summit",
//     description:
//       "Learn The Secrets To Find Peace And Leave The Trauma Behind, From 40 Field Experts, No Matter How Bad, Recent, Or Crippling It Might Be Even If Everything Has Been Tried... Coping with Trauma Summit is an international event bringing together multiple specialists and experts to provide tools and strategies. Get the recordings and speaker notes of the Summit 2020 Edition, an excellent chance to discover resources and strategies to heal from trauma.",
//     button: "Join the Summit Program",
//     image:
//       "https://images.dowell-ht.com/images/react-dowell-ht/coping-with-trauma-summit.png",
//     link: "https://go.dowellht.com/complete-edition-cwt-summit-2020/",
//   },
// ];

const OurProducts = [
  {
    key: "paperback",
    class: "grid",
    name: "International Bestseller Put That Stuff Down, Coping with PTSD through a decluttering journey",
    description:
      "Put That Stuff Down takes its readers on a decluttering journey to find peace of mind, gain healthier time practice, enjoy your life into an organized and welcoming home — all that to finally cope with PTSD. It’s packed with simple actions to get started on this powerful journey!",
    button: [
      "Get the paperback book",
      "Get the digital version: e-book & audiobook with bonuses",
    ],
    image: "https://images.dowell-ht.com/images/psd-book-mockup-01.png",
    link: [
      "https://go.dowellht.com/put-that-stuff-down-paperback-book1?el=dowellhtproducts",
      "https://try.dowellht.com/get-bestseller-clutter-trauma?el=dowellhtproducts",
    ],
  },
  {
    key: "newbook",
    class: "reverse grid new",
    name: "Decluttering: How can I get started book",
    description:
      "Decluttering: How can I get started answers the fundamentals and gives a panel of tactics and step-by-step plans. This book helps you start decluttering with clear action steps. It works, even if you are feeling stressed and overwhelmed. You will learn the proven strategies and tools that helped thousands of people.",
    button: ["Get the book"],
    image: "https://images.dowell-ht.com/images/new-book-mockup-01.png",
    link: [
      "https://go.dowellht.com/decluttering-started-book-do010ac?el=dowellhtproducts",
    ],
  },
  {
    key: "academy",
    class: "grid",
    name: "DOers Academy",
    description: `The DOers Academy is the leading training community for people who want to let go of trauma & clutter for good. It's a membership to help you get a peaceful home and create an amazing life! The Academy was built from the process we developed that now helped over 1000 people to live the happy and amazing life they deserve. And we seriously love spending our days supporting our members.`,
    button: ["Join the Academy!"],
    image: "https://images.dowell-ht.com/images/da-mockup-01.png",
    link: ["https://try.dowellht.com/doersacademy?el=dowellhtproducts"],
  },
  {
    key: "smg",
    class: "reverse grid",
    name: "Small Momentum Group One Year Accountability and Coaching Program",
    description:
      "This program is not for everyone. It’s a full year commitment of transformation, decluttering, developing a strong mindset and peace of mind. It’s by application only. The program includes structured on-demand training with the full 4-step decluttering and organizing method, live weekly coaching, accountability, personalized decluttering solutions and life changing.",
    button: ["Apply now and book your call"],
    image: "https://images.dowell-ht.com/images/smg-mockup-01.png",
    link: [
      "https://form.jotform.com/221154604479052?utm_source=dowellhtproducts",
    ],
  },
  {
    key: "ssm",
    class: "grid",
    name: "Secrets to Save Money from Decluttering Online Program",
    description:
      "Want to declutter and stop wasting and want to save money. That is why we built the secrets to save money from decluttering program. In short it’s: All-Important Financial Decision discussed, Budget and Expense Template, Track your expenses and cash flow to improve your budgeting, Essential Grocery List, Comparison between a healthy grocery, healthy, Healthy Meal Prep, Elements of a fresh and healthy menu so you cut down on take-out ordering, Turn your stuff into money ... And much more!",
    button: ["Join the Program"],
    image: "https://images.dowell-ht.com/images/ssm-mockup-01.png",
    link: [
      "https://go.dowellht.com/the-moneysecrets-from-decluttering1?el=dowellhtproducts",
    ],
  },
  {
    key: "summit",
    class: "reverse grid",
    name: "Coping With Trauma Summit 2020 VIP Recordings",
    description:
      "Learn The Secrets To Find Peace And Leave The Trauma Behind, From 40 Field Experts, No Matter How Bad, Recent, Or Crippling It Might Be Even If Everything Has Been Tried... Coping with Trauma Summit is an international event bringing together multiple specialists and experts to provide tools and strategies. Get the recordings and speaker notes of the Summit 2020 Edition, an excellent chance to discover resources and strategies to heal from trauma.",
    button: ["Join the Summit Program"],
    image: "https://images.dowell-ht.com/images/summit-mockup-01.png",
    link: [
      "https://go.dowellht.com/complete-edition-cwt-summit-2020?el=dowellhtproducts",
    ],
  },
];

const ProductArray = [
  {
    image: `https://images.dowell-ht.com/images/react-dowell-ht/image-004.png`,
    name: "Put That Stuff Down: The Book",
    description:
      "Our 112-page book teaches readers about the fundamentals of PTSD, how it can manifest in everyday life, and actions they can take to overcome these obstacles.",
    button: "Buy the book",
    key: 1,
    url: "https://go.dowellht.com/put-that-stuff-down-paperback-book1?el=dowellhtproducts",
  },
  {
    image: `https://images.dowell-ht.com/images/react-dowell-ht/youtube-mockup.png`,
    name: "Watch us on YouTube",
    description:
      "You can watch our videos that could help you deal with trauma and clutter 24/7. You can also find interviews with professionals and psychologists.",
    button: "Visit us on YouTube",
    key: 2,
    url: "https://www.youtube.com/c/PostTraumaSecretsDeclutteringbyDOWell",
  },
  {
    image: `https://images.dowell-ht.com/images/react-dowell-ht/image-006.png`,
    name: "Post Trauma Secrets & Decluttering: The Podcast",
    description:
      "Hosted by Valerie Huard and JM Tetreault, Post Trauma Secrets & Decluttering brings you on a journey from trauma to Joie de Vivre!",
    button: "Access the Podcast",
    key: 3,
    url: "https://podcasts.apple.com/ca/podcast/post-trauma-secrets-decluttering/id1552770563",
  },
];

const Content1 = () => {
  return (
    <>
      <Components.Container>
        <Components.Content className="max-width-1080px ">
          <div className="spread-30">
            <div className="heading">
              <span className="rectangle" />
              <h1>Our Products</h1>
            </div>
            {OurProducts.map((i) => {
              return (
                <div className={i.class} key={i.key}>
                  <img src={i.image} alt="/" />
                  <div className="word-content spread-30">
                    <div>
                      {/* <span className="rectangle-2" /> */}
                      <h2>{i.name}</h2>
                    </div>
                    <p>{i.description}</p>
                    {i.button.map((j, index) => {
                      return (
                        <a className="button" href={i.link[index]}>
                          {j}
                        </a>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
          <ProductsDisplay ProductArray={ProductArray} />
        </Components.Content>
      </Components.Container>
    </>
  );
};

export default Content1;
