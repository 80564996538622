import styled from "styled-components";

export const Container = styled.div`
  padding: 5rem 0;
  background-color: var(--bg-60);
  box-shadow: 0 0 20px 10px rgba(0, 0, 0, 0.1);
`;
export const Content = styled.div`
  text-align: center;
  *,
  a {
    color: var(--text-white);
  }
  a:hover {
    opacity: 0.8;
  }

  .copyright {
    font-size: 0.8rem;
  }
`;
export const FooterLinksContainer = styled.div`
  display: grid;
  row-gap: 30px;
  margin-bottom: 50px;

  @media screen and (min-width: 550px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 30px;
  }

  @media screen and (min-width: 768px) {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
  }

  .footerLinksContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
`;
export const FooterMainLink = styled.a`
  text-decoration: underline;
`;
export const FooterLink = styled.a``;
