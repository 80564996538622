import styled from "styled-components";

export const Container = styled.div`
  padding: 5rem 0;
  background-image: url(https://images.dowell-ht.com/images/react-dowell-ht/bg-002.png);
  background-size: cover;
  background-position: center;

  .socials {
    padding-top: 5rem;
  }

  .socials-container {
    display: grid !important;
    grid-row-gap: 30px;
    grid-column-gap: 30px;
    padding-top: 2rem;

    @media screen and (min-width: 480px) {
      grid-template-columns: repeat(2, 1fr) !important;
    }

    @media screen and (min-width: 768px) {
      grid-template-columns: repeat(3, 1fr) !important;
    }

    a {
      display: block;
    }

    img {
      height: 80px;
      width: auto;
    }
  }

  .social-container {
    background-color: var(--bg-white);
    padding: 2rem;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 250px;
    margin-bottom: 0 !important;

    * {
      text-align: center;
    }

    a.social-link:first-of-type {
      margin-bottom: 20px;
    }

    h4 {
      font-size: 1.3rem;
      color: var(--text-60);
      line-height: 100%;
      margin-bottom: 10px;
    }

    p {
      line-height: 100%;
    }
  }
`;
export const Content = styled.div`
  > div {
    &:first-of-type {
      @media screen and (min-width: 768px) {
        display: flex;
        align-items: center;
      }
    }
    > :not(:last-child) {
      margin-bottom: 30px;
      @media screen and (min-width: 768px) {
        margin-bottom: 0;
      }
    }
    &:not(:last-child) {
      margin-bottom: 30px;
      @media screen and (min-width: 768px) {
        margin-bottom: 100px;
      }
    }
    > div.rectangle {
      height: 20px;
      width: 80%;
      max-width: 500px;
      background-color: var(--bg-10);
      @media screen and (min-width: 768px) {
        height: 100px;
        width: 20px;
        margin-right: 30px;
      }
    }
    &.content3-content {
      display: grid;
      justify-items: center;
      grid-auto-flow: dense;
      gap: 30px;
      > div {
        > p {
          font-size: 1.25rem;
          line-height: 200%;
          font-weight: var(--light);
          color: var(--text-white);
          &:not(:last-of-type) {
            margin-bottom: 30px;
          }
          strong {
            color: var(--text-white);
            font-weight: var(--bold);
          }
        }
        @media screen and (min-width: 768px) {
          grid-column-start: 1;
        }
      }
      @media screen and (min-width: 768px) {
        grid-template-columns: 1fr 1fr;
        gap: 30px;
      }
      > img {
        /* max-width: 400px; */
        @media screen and (min-width: 768px) {
          grid-column-start: 2;
        }
      }
    }
  }

  h2 {
    font-weight: var(--bold);
    font-size: 3rem;
    line-height: 100%;
    color: var(--text-white);
    @media screen and (min-width: 768px) {
      max-width: 800px;
    }
  }

  /* div.products-display {
    display: grid;
    @media screen and (min-width: 768px) {
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 30px;
    }
  } */
`;
