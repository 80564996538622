import React from "react";
import Content1 from "../components/products/Content1";

const Products = () => {
  return (
    <>
      <Content1 />
    </>
  );
};

export default Products;
