import React from "react";
import * as Components from "./styles/NewTestimonial.styled";

const NewTestimonial = () => {
  return (
    <>
      <Components.Container>
        <Components.Content className="max-width-1080px">
          <div className="testimonial-container">
            <div className="testimonial-content">
              <p>
                <img
                  src="https://images.dowell-ht.com/images/quote-mark-001.png"
                  className="quote-mark"
                  alt="/"
                />
                Who would think that something as simple as de-cluttering your
                life, your living space and your mind could help you heal from
                PTSD? This easy and effective technique is empowering PTSD
                victims to take charge of their own healing and move toward
                reclaiming their ‘normal’ life.
                <img
                  src="https://images.dowell-ht.com/images/quote-mark-002.png"
                  className="quote-mark"
                  alt="/"
                />
              </p>
              <div className="testimonial-quote">
                <img
                  src="https://images.dowell-ht.com/images/91t5PCeUWXL._SX450_.jpg"
                  alt="/"
                  className="testimonial-pic"
                />
                <div className="testimonial-person">
                  <h3>Barry Spilchuk</h3>
                  <p className="small">
                    Founder of www.TheLEGACY.club and Coauthor of A Cup of
                    Chicken Soup for the Soul
                  </p>
                </div>
              </div>
            </div>
            <img
              src="https://images.dowell-ht.com/images/testimonial-image-001.png"
              className="testimonial-cover"
              alt="/"
            />
          </div>
          <div className="testimonial-container">
            <div className="testimonial-content">
              <p>
                <img
                  src="https://images.dowell-ht.com/images/quote-mark-001.png"
                  className="quote-mark"
                  alt="/"
                />
                You will be armed with the tools to live the life you dream
                about. Valérie and Jean-Michel are ready to help you with it. Do
                what they suggest. If you do, you will feel better. You will
                discover that you were born to enjoy your life.
                <img
                  src="https://images.dowell-ht.com/images/quote-mark-002.png"
                  className="quote-mark"
                  alt="/"
                />
              </p>
              <div className="testimonial-quote">
                <img
                  src="https://images.dowell-ht.com/images/GerryRobert00819-1-headshot.png"
                  alt="/"
                  className="testimonial-pic"
                />
                <div className="testimonial-person">
                  <h3>Gerry Robert</h3>
                  <p className="small">
                    Speaker and International Bestselling Author of The
                    Millionaire Mindset, Multiply Your Business and Publish a
                    Book & Grow Rich
                  </p>
                </div>
              </div>
            </div>
            <img
              src="https://images.dowell-ht.com/images/testimonial-image-002.png"
              className="testimonial-cover"
              alt="/"
            />
          </div>
        </Components.Content>
      </Components.Container>
    </>
  );
};

export default NewTestimonial;
