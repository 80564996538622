import React from "react";
import * as Components from "./styles/Footer.styled";

const Footer = () => {
  const footerLinksArr = [
    {
      name: "DO Well®",
      path: "http://the.dowellht.com",
      key: "main",
      sublinks: [
        { name: "About Us", path: "about-us", key: "aboutUs" },
        { name: "Contact Us", path: "contact-us", key: "contactUs" },
        {
          name: "Privacy Policy",
          path: "privacy-policy",
          key: "privacyPolicy",
        },
        {
          name: "Terms & Conditions",
          path: "terms-of-use",
          key: "termsAndConditions",
        },
      ],
    },
    {
      name: "Community",
      path: "/",
      key: "community",
      sublinks: [
        {
          name: "DOers Facebook Group",
          path: "https://www.facebook.com/groups/doerscommunity",
          key: "doersFacebookGroup",
        },
        {
          name: "Youtube Channel",
          path: "https://www.youtube.com/c/PostTraumaSecretsDeclutteringbyDOWell",
          key: "ytChannel",
        },
        {
          // no link
          name: "Help and Support",
          path: "contact-us",
          key: "helpAndSupport",
        },
        {
          // no link
          name: "Careers",
          path: "career",
          key: "careers",
        },
      ],
    },
    {
      name: "Programs",
      path: "products",
      key: "programs",
      sublinks: [
        {
          name: "The Art of letting go Blueprint (free)",
          path: "https://try.dowellht.com/get-the-art-of-letting-go-blueprint?el=dowellhtmainfooter",
          key: "freeRoadmap",
        },
        {
          // no link
          name: "Books",
          path: "products",
          key: "books",
        },
        {
          // no link
          name: "Programs",
          path: "products",
          key: "programs",
        },
      ],
    },
    {
      name: "Blog",
      path: "https://library.dowellht.com/",
      key: "blog",
      sublinks: [
        {
          name: "Decluttering & Organizing",
          path: "https://library.dowellht.com/decluttering-and-organizing/",
          key: "declutteringAndOrganizing",
        },
        {
          name: "Calm & Stress",
          path: "https://library.dowellht.com/calm-and-stress/",
          key: "calmAndStress",
        },
        {
          name: "Mindset",
          path: "https://library.dowellht.com/mindset/",
          key: "mindset",
        },
        {
          name: "Time & Focus",
          path: "https://library.dowellht.com/time-and-focus/",
          key: "timeAndFocus",
        },
      ],
    },
  ];

  return (
    <>
      <Components.Container>
        <Components.Content className="max-width-1080px">
          <Components.FooterLinksContainer>
            {footerLinksArr.map((i) => {
              return (
                <div key={i.key} className="footerLinksContainer">
                  <Components.FooterMainLink href={i.path}>
                    {i.name}
                  </Components.FooterMainLink>
                  {i.sublinks.map((j) => {
                    return (
                      <Components.FooterLink href={j.path} key={j.key}>
                        {j.name}
                      </Components.FooterLink>
                    );
                  })}
                </div>
              );
            })}
          </Components.FooterLinksContainer>
          <p className="copyright">
            © Copyright 2022 - All Rights Reserved, DO Well®
          </p>
        </Components.Content>
      </Components.Container>
    </>
  );
};

export default Footer;
