import React from "react";
import * as Components from "./styles/Content2.styled";

const Content2 = () => {
  return (
    <>
      <Components.Container>
        <Components.Content className="max-width-1080px">
          <img
            src="https://images.dowell-ht.com/images/react-dowell-ht/image-016.png"
            alt="/"
          />
        </Components.Content>
      </Components.Container>
    </>
  );
};

export default Content2;
