import styled from "styled-components";

export const Container = styled.div`
  padding: 5rem 0;
  background-color: var(--bg-60);
  background-image: url(https://images.dowell-ht.com/images/background-002.png);
  background-position: center;
  background-size: cover;
  overflow: hidden;
`;
export const Content = styled.div`
  display: flex;
  align-items: center;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }

  .da-words {
    display: flex;
    flex-direction: column;
    width: 60%;

    @media screen and (max-width: 954px) {
      width: 70%;
    }

    @media screen and (max-width: 768px) {
      width: 100%;
    }

    @media screen and (max-width: 450px) {
      * {
        text-align: center;
      }
    }
  }

  .da-intro {
    position: relative;
    font-size: 1.1rem;
    margin-bottom: 30px;
    color: var(--text-white);
    font-weight: 500;
    z-index: 10;
    padding: 1rem 0;

    &::before {
      content: "";
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      height: 100%;
      width: 1000px;
      background-color: var(--bg-60);
      z-index: -20;
      border-radius: 20px;
    }
  }

  .da-image {
    object-fit: cover;
    object-position: center;
    height: 300px;
    width: auto;

    @media screen and (max-width: 954px) {
      height: 250px;
    }
  }

  a {
    display: block;
  }

  h3,
  p,
  strong {
    color: var(--text-white);
  }

  h3 {
    font-size: 3rem;
  }

  strong {
    font-weight: 700;
  }

  p {
    margin-bottom: 30px;
  }

  a.button {
    display: block;
    padding: 1rem 5rem;
    background-color: var(--bg-60);
    border: none;
    border-radius: 20px;
    cursor: pointer;
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-size: 1.15rem;
    color: var(--text-white);

    &:hover {
      opacity: 0.8;
    }

    @media screen and (max-width: 450px) {
    }
  }
`;
