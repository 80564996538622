import styled from "styled-components";

export const Container = styled.div`
  &.testimonial {
    padding: 5rem 0;
    background-color: rgba(0, 0, 0, 0.5);
    margin: 30px 0 0;
  }

  &.meet-the-team {
    padding: 5rem 0;
    background-color: var(--bg-white);

    h2 {
      font-size: clamp(2rem, 5vw, 3rem);
      line-height: 100%;
      font-weight: var(--light);
      max-width: 800px;
      letter-spacing: 0.2rem;
      color: var(--text-black);
    }
    p {
      font-size: 1.15rem;
      font-weight: var(--light);
      max-width: 800px;
      color: var(--text-black);
    }
    a {
      display: inline-block;
      box-sizing: border-box;
      width: 100%;
      max-width: 300px;
      color: var(--text-white);
      &:hover {
        opacity: 0.8;
      }
    }
  }
`;
export const Content = styled.div`
  > div {
    &:not(:last-of-type) {
      margin-bottom: 100px;
    }
  }
  div {
    &.content-container {
      > *:not(:last-child) {
        margin-bottom: 30px;
      }
    }
    &.testimonial-grid {
      display: grid;
      gap: 10px;
      @media screen and (min-width: 768px) {
        grid-template-columns: repeat(3, 1fr);
      }
    }
    &.grid {
      display: grid;
      @media screen and (min-width: 768px) {
        grid-template-columns: repeat(2, 1fr);
        gap: 30px;
      }
    }
  }
  h2 {
    font-size: clamp(2rem, 5vw, 3rem);
    line-height: 100%;
    font-weight: var(--light);
    max-width: 800px;
    letter-spacing: 0.2rem;
    color: var(--text-white);
  }
  p {
    font-size: 1.15rem;
    font-weight: var(--light);
    max-width: 800px;
    color: var(--text-white);
  }
  a {
    display: inline-block;
    box-sizing: border-box;
    width: 100%;
    max-width: 300px;
    color: var(--text-white);
    &:hover {
      opacity: 0.8;
    }
  }

  .emphasize {
    margin: 0 auto;
    text-align: center;
    font-size: 1.5rem;
    font-style: italic;
  }
`;
