import React from "react";
import * as Components from "./styles/Testimonials.styled";

import NewTestimonial from "./NewTestimonial";
import PSDBook from "./PSDBook";
import DoersAcademy from "./DoersAcademy";

const Testimonials = () => {
  return (
    <>
      <Components.Container className="testimonial">
        <Components.Content className="max-width-1080px">
          <div className="content-container">
            <h2>WE UNDERSTAND HOW DIFFICULT OVERCOMING TRAUMA IS</h2>
            <p>
              Addressing and dealing with trauma is no easy feat, even despite
              the various coping strategies you may have adopted along the way.
            </p>
            <p className="emphasize">
              Discover What Some Of Our Clients Say About Their Unique DO Well
              Experience:
            </p>
            <div className="testimonial-grid">
              <img
                src="https://images.dowell-ht.com/images/react-dowell-ht/testimonial-01.png"
                alt="/"
              />
              <img
                src="https://images.dowell-ht.com/images/react-dowell-ht/testimonial-02.png"
                alt="/"
              />
              <img
                src="https://images.dowell-ht.com/images/react-dowell-ht/testimonial-03.png"
                alt="/"
              />
            </div>
          </div>
        </Components.Content>
      </Components.Container>

      <Components.Container className="meet-the-team">
        <Components.Content className="max-width-1080px">
          <div className="content-container grid">
            <div className="content-container">
              <h2>MEET THE TEAM</h2>
              <p>
                Wife and husband duo, Valérie Huard and JM Tetreault, created DO
                Well to encourage people to break free from their trauma through
                home organizing, decluttering, and staging. Click the button
                below to read their stories and discover the inspiration behind
                the brand.
              </p>
              <a href="/about-us" className="button">
                Learn more
              </a>
            </div>
            <img
              src="https://images.dowell-ht.com/images/react-dowell-ht/image-010.png"
              alt="/"
            />
          </div>
        </Components.Content>
      </Components.Container>

      <NewTestimonial />
      <PSDBook />
      <DoersAcademy />
    </>
  );
};

export default Testimonials;
