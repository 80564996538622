import React from "react";
import styled from "styled-components";

const PageNotFound = () => {
  return (
    <Container>
      <span className="menu-bar-background" />
      <Content className="max-width-1080px">
        <h1>Sorry! This page is in decluttering mode.</h1>
        <p>
          We couldn’t find what you’re looking for. Try visit our{" "}
          <a href="/">homepage</a>.
        </p>
        <p>Status: 404</p>
        <p>Message: Information not found…</p>
      </Content>
    </Container>
  );
};

export default PageNotFound;

// styles
const Container = styled.div`
  padding: 10rem 0 5rem;

  .menu-bar-background {
    position: absolute;
    top: 0;
    display: block;
    height: 80px;
    width: 100vw;
    background-color: var(--bg-60);
  }
`;
const Content = styled.div`
  a {
    color: var(--text-60);
    text-decoration: underline;
  }
`;
