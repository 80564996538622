import React from "react";
import * as Components from "./styles/AboutUs.styled";

const AboutUs = () => {
  return (
    <>
      <Components.Container>
        <Components.Content className="max-width-1080px">
          <h1>About DO Well</h1>
          <p>
            DO Well is a Health and Wellness Company that delivers education,
            and an ecosystem of tools and services to create one’s desired life
            and improve well-being.
          </p>
          <p>
            {" "}
            We are an enthusiastic team that believes your mind and your home
            are very precious for your enjoyment of life and your growth. Our
            virtual team is there to help you create the life you deserve and
            you want.
          </p>
          <br />
          <h2>The Founders</h2>
          <div className="valerie meet">
            <a href="meet-valerie">
              <img
                src="https://images.dowell-ht.com/images/react-dowell-ht/image-013.png"
                alt="/"
                className="meet-image"
              />
            </a>
            <div>
              <h4>Valerie Huard</h4>
              <p>Head Coach, Professional Organizer and Decluttering Expert</p>
              <a href="meet-valerie" className="learn-more">
                Learn more
              </a>
            </div>
          </div>
          <div className="jm meet">
            <a href="meet-jm">
              <img
                src="https://images.dowell-ht.com/images/react-dowell-ht/image-017.png"
                alt="/"
                className="meet-image"
              />
            </a>
            <div>
              <h4>JM Tetreault</h4>
              <p>Mindset Coach & Business System and Organization Expert</p>
              <a href="meet-jm" className="learn-more">
                Learn more
              </a>
            </div>
          </div>
        </Components.Content>
      </Components.Container>
    </>
  );
};

export default AboutUs;
