import React from "react";
import * as Components from "./styles/Content2.styled";

const Content2 = () => {
  return (
    <>
      <Components.Container>
        <Components.Content className="max-width-1080px">
          <img
            src="https://images.dowell-ht.com/images/react-dowell-ht/image-002.png"
            alt="/"
          />
          <div className="content" id="content-1">
            <h2>are you struggling with a messy home?</h2>
            <p>
              When living with trauma, having an untidy home can be incredibly
              taxing. In your everyday life, you may…
            </p>
            <ul>
              <li>Re-live horrifying events through nightmares</li>
              <li>Experience debilitating depression and anxiety</li>
              <li>Feel angry when people tell you to ‘just get over it’</li>
              <li>Face confusion, demotivation, and fear</li>
              <li>Not know where to start with adopting a lifestyle change</li>
            </ul>
            <p>
              …each of which leads to papers stacking up, dishes remaining
              unwashed, and old belongings that should have been thrown out long
              ago.
            </p>
            <p>
              That is where DO Well comes in, helping clients exchange their
              trauma for happier lives.
            </p>
          </div>
        </Components.Content>
      </Components.Container>
    </>
  );
};

export default Content2;
