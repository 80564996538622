import styled from "styled-components";

export const Container = styled.div`
  padding: 5rem 0;
  background-color: greenyellow;
  background-color: #49a1a1;
`;
export const Content = styled.div`
  .testimonial-container {
    position: relative;
    overflow: hidden;
    padding: 5rem;
    background-color: var(--bg-white);
    border-radius: 20px;

    &:not(:last-of-type) {
      margin-bottom: 30px;
    }

    @media screen and (max-width: 768px) {
      padding: 3rem;
    }
  }

  .testimonial-content {
    width: 60%;
    display: flex;
    flex-direction: column;
    gap: 30px;

    @media screen and (max-width: 768px) {
      width: 100%;
    }

    > p:first-of-type {
      position: relative;

      .quote-mark {
        position: absolute;

        &:first-of-type {
          top: -30px;
          left: -30px;
        }

        &:last-of-type {
          right: -20px;
          bottom: -20px;
        }
      }
    }
  }

  .testimonial-pic {
    height: 120px;
    width: 120px;
    border-radius: 200px;
    object-fit: cover;
    object-position: center;
  }

  .testimonial-quote {
    display: flex;
    align-items: center;
    gap: 30px;

    @media screen and (max-width: 768px) {
      flex-direction: column;
    }
  }

  .testimonial-person {
    color: var(--text-60);

    @media screen and (max-width: 768px) {
      h3,
      p {
        text-align: center;
      }
    }
  }

  h3 {
    font-size: 1.75rem;
    color: var(--text-60);
  }

  p.small {
    font-size: 0.8rem;
    color: var(--text-60);
  }

  .testimonial-cover {
    position: absolute;
    top: -100px;
    right: -250px;
    height: 600px;

    @media screen and (max-width: 940px) {
      height: 500px;
      right: -150epx;
      top: -20px;
    }

    @media screen and (max-width: 768px) {
      display: none;
    }
  }
`;
