import styled from "styled-components";

export const Container = styled.div`
  padding: 5rem 0 5rem;
  background-image: url(https://images.dowell-ht.com/images/react-dowell-ht/bg-001.png);
  background-position: center;
  background-size: cover;

  .form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }

  .submitBtn {
    padding: 0.5rem 4rem;
    font-family: "Poppins", sans-serif;
    font-size: 1.05rem;
    font-weight: 600;
    background-color: var(--bg-10);
    border: none;
    border-radius: 10px;
    color: var(--text-white);
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }
  }

  input,
  textarea {
    font-family: "Poppins", sans-serif;
    font-size: 1rem;
    margin-bottom: 10px;
    padding: 0.5rem;
    border-radius: 10px;
    border: none;
    resize: none;
    width: 100%;
  }

  .socials {
    padding-top: 5rem;

    h2 {
      color: var(--text-white);
    }
  }

  .socials-container {
    display: grid !important;
    grid-row-gap: 30px;
    grid-column-gap: 30px;
    padding-top: 2rem;

    @media screen and (min-width: 480px) {
      grid-template-columns: repeat(2, 1fr) !important;
    }

    @media screen and (min-width: 768px) {
      grid-template-columns: repeat(3, 1fr) !important;
    }

    a {
      display: block;
    }

    img {
      height: 80px;
      width: auto;
    }
  }

  .social-container {
    background-color: var(--bg-white);
    padding: 2rem;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 250px;
    margin-bottom: 0 !important;

    * {
      text-align: center;
    }

    a.social-link:first-of-type {
      margin-bottom: 20px;
    }

    h4 {
      font-size: 1.3rem;
      color: var(--text-60);
      line-height: 100%;
      margin-bottom: 10px;
    }

    p {
      line-height: 100%;
    }
  }
`;
export const Content = styled.div`
    margin-bottom:5rem;

  h1,
  h2,
  li {
    color: var(--text-white);
  }

  h1 {
    letter-spacing: 0.5rem;
    font-size: clamp(4rem, 10vw, 6rem);
    line-height: 80%;
    @media screen and (min-width:768px){
        width:150%;
    }
  }

  h2 {
      font-weight:300;
    font-size: clamp(1.5rem, 5vw, 2rem);
    line-height:100%;
    @media screen and (min-width:768px){
        width:150%;
    }
  }

  ul{
      list-style:none;
      padding:2rem;
      border:2px solid var(--bg-white);
      border-radius:10px;
    @media screen and (min-width:768px){
        width:150%;
    }

  }

  li {
    font-size: clamp(1rem, 5vw, 1.5rem););
    font-weight:var(--light);
  }

  a,u{
      color:var(--text-10);
  }

  span{
      &.rectangle{
          display:inline-block;
          height:20px;
          width:50%;
          background-color:var(--bg-10);
          margin-bottom:0 !important;
          @media screen and (max-width:768px){
              display:none
          }
      }
  }

  img{
      justify-self:center;
      margin-top:-5rem;
      @media screen and (min-width:768px){
          max-width:100%;
          margin-top:0;
        grid-column-start:2;
        transform:translateX(20%);
      }
  }


  .grid{
      display:grid;
      grid-gap:30px;
      @media screen and (min-width:768px){
          grid-template-columns:repeat(2,1fr);
          grid-auto-flow:dense;
      }
  }

  .spread-30 {
    > *:not(:last-child) {
      margin-bottom: 30px;
    }
  }

  .word-content {
    margin-top: -5rem;
    @media screen and (min-width:768px){
        margin-top:5rem;
        grid-column-start:1;
        z-index:5;
    }
  }
`;
