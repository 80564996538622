import styled from "styled-components";

export const Container = styled.div`
  margin-top: 180px;
`;
export const Content = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    &.product-image {
      position: absolute;
      top: -150px;
      height: 300px;
      width: auto;
      margin: 0 auto;
    }
  }

  div.product-desc-container {
    background-color: var(--bg-white);
    padding: 10rem 2rem 2rem;
    border-radius: 10px;
    box-shadow: 0 0 20px -10px var(--bg-black);
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    > *:not(:last-child) {
      margin-bottom: 10px;
    }
  }

  h3,
  p {
    text-align: center;
  }

  h3 {
    font-weight: var(--bold);
    font-size: 1.5rem;
    line-height: 100%;
  }

  p {
    line-height: 200%;
    font-weight: var(--light);
    color: var(--text-black) !important;
  }

  a {
    color: var(--text-white);
    &.button {
      width: 100%;
      &:hover {
        opacity: 0.7;
      }
    }
  }
`;
