import React from "react";
import styled from "styled-components";
import { BiUpArrowCircle } from "react-icons/bi";

const ScrollToTop = ({ scrollToTop }) => {
  return (
    <>
      <Container onClick={scrollToTop}>
        <BiUpArrowCircle />
      </Container>
    </>
  );
};

const Container = styled.button`
  border: none;
  position: fixed;
  background: transparent;
  right: 2rem;
  bottom: 5rem;
  cursor: pointer;

  svg {
    height: 50px;
    width: auto;
    fill: var(--bg-60);
    background-color: var(--bg-white);
    border-radius: 50px;
  }

  &:hover {
    transform: scale(1.2);
  }
`;

export default ScrollToTop;
